import React from 'react';
import PropTypes from 'prop-types';
import { withHereMap } from './HereMapContext';
import { isEqual } from 'lodash';
import polylineOffset from './polylineOffset';
import { addEventListeners, removeEventListeners } from './mapEvents';

class HerePolyline extends React.Component {
  polyline = null;

  componentDidMount() {
    this.addPolyline();
  }

  componentDidUpdate(prevProps) {
    const { latLngs, options } = this.props;

    if (!isEqual(options.style, prevProps.options.style)) {
      this.polyline && this.polyline.setStyle(options.style);
    }

    const optionsNoStyle = { ...options };
    delete optionsNoStyle.style;
    const prevOptionsNoStyle = { ...prevProps.options };
    delete prevOptionsNoStyle.style;

    if (
      !isEqual(latLngs, prevProps.latLngs) ||
      !isEqual(optionsNoStyle, prevOptionsNoStyle)
    ) {
      this.removePolyline();
      this.addPolyline();
    }
  }

  componentWillUnmount() {
    this.removePolyline();
  }

  changeCursorAuto = () => {
    this.props.map.getElement().style.cursor = 'auto';
  };

  changeCursorPointer = () => {
    this.props.map.getElement().style.cursor = 'pointer';
  };

  addPolyline = () => {
    const { latLngs, options, map, group, type } = this.props;
    let newLatLngs = null;
    if (options.offset != null) {
      newLatLngs = polylineOffset(latLngs, options.offset);
    }
    if ( type === 'rasterArrow' ) {
      options.arrows = true;
    } else if ( type === 'vectorArrow' ) {
      options.arrows = true;
      options.style = {
        lineWidth: 8,
        strokeColor: '#C4D1E5',
        lineDash: [1, 5],
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head',
        lineDashImage: window.H.map.SpatialStyle.DashImage.ARROW
      }
    }
    const geometry = new window.H.geo.LineString();
    (newLatLngs || latLngs).forEach((latLng) => {
      const { lat, lng } = latLng || {};
      if ((lat || lat === 0) && (lng || lng === 0)) {
        geometry.pushLatLngAlt(lat, lng);
      }
    });

    if (geometry.getPointCount() < 2) {
      return
    }
    this.polyline = new window.H.map.Polyline(geometry, options);

    if (this.props.changeCursorOnHover && this.polyline) {
      this.polyline.addEventListener('pointerenter', this.changeCursorPointer);
      this.polyline.addEventListener('pointerleave', this.changeCursorAuto);
    }
    if (this.props.onTap && this.polyline) {
      this.polyline.addEventListener('tap', this.props.onTap);
    }
    addEventListeners(this.props, this.polyline);

    if (group) {
      group.addObject(this.polyline);
    } else {
      map.addObject(this.polyline);
    }
  };

  removePolyline = () => {
    const { group } = this.props;
    this.polyline && this.polyline.removeEventListener('pointerenter', this.changeCursorPointer);
    this.polyline && this.polyline.removeEventListener('pointerleave', this.changeCursorAuto);
    if (this.props.onTap && this.polyline) {
      this.polyline.removeEventListener('tap', this.props.onTap);
    }
    removeEventListeners(this.props, this.polyline);
    if (this.polyline && group && group.contains(this.polyline)) {
      group.removeObject(this.polyline);
    }
  };

  render() {
    return null;
  }
}

HerePolyline.propTypes = {
  latLngs: PropTypes.arrayOf(PropTypes.object).isRequired,
  map: PropTypes.object.isRequired,
  options: PropTypes.object,
  group: PropTypes.object,
  changeCursorOnHover: PropTypes.bool,
};

export default withHereMap(HerePolyline);
