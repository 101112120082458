import React from 'react';
import PropTypes from 'prop-types';
import TabSortable from './TabSortable';
import utils from 'utils';
import {
  FormRow,
  Textarea,
  TabsControl,
} from '@here/ref-client-ui';
import { validateLon, validateLat } from 'utils/validation/coordsValidation';
import './multiplePoints.scss';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import isFinite from 'lodash/isFinite';
import { createSelector } from 'reselect';
import adjustLUIStyle from '../../../utils/adjustLUIStyle';
import { decode, encode } from '../../../utils/flexPolyline';
import BigNumber from 'bignumber.js';

const isPointValid = (point) =>
  isFinite(+point.lat) &&
  isFinite(+point.lng) &&
  validateLat(+point.lat) &&
  validateLon(+point.lng);

const isValid = createSelector(
  (points) => points,
  (points) => {
    if (!isArray(points)) {
      return false;
    }
    return every(points, isPointValid);
  }
);

const isPolygonPoint = (point) =>
  point.hasOwnProperty('lat') &&
  point.hasOwnProperty('lng');

const isPolygon = createSelector(
  (points) => points,
  (points) => {
    if (!isArray(points)) {
      return false;
    }
    return every(points, isPolygonPoint);
  }
);

class MultiplePoints extends React.Component {
  static PointTabs = {
    TEXT_AREA: 'textArea',
    POINTS: 'points',
    ENCODED_POLYGON: 'encodedPolygon',
  };

  state = {
    // eslint-disable-next-line no-use-before-define
    currentPointsTab: MultiplePoints.PointTabs.POINTS,
  };
  
  componentDidMount () {
    adjustLUIStyle(document.querySelectorAll('.rf-add-point'), 'lui-button');
  }

  componentWillMount () {
    const { points } = this.props;
    if (points.length === 1 &&  typeof points[0] === 'string') {
      this.setState({ currentPointsTab: MultiplePoints.PointTabs.ENCODED_POLYGON})
    }
  }

  componentDidUpdate () {
    const { onChange } = this.props;
    if (this.pointsCache) {
      const type = this.pointsCache.length > 0 && typeof this.pointsCache[0] === "string";
      onChange(this.pointsCache, type? MultiplePoints.PointTabs.ENCODED_POLYGON:null);
      this.pointsCache = null;
    }
    adjustLUIStyle(document.querySelectorAll('.rf-add-point'), 'lui-button');
  }

  onChangePoint = (num, e) => {
    const { points, onChange } = this.props;
    const pointStr = utils.extractData(e);
    if (pointStr) {
      const [lat, lng] = pointStr
        .split(',')
        .map((coord) => (isNaN(coord) ? coord : parseFloat(coord)));
      points[num] = { ...points[num], ...{ lat, lng }};
      onChange(points);
    }
  };

  onChangeAdvanced = (num, key, e) => {
    const { points, onChange, options } = this.props;
    const value = utils.extractData(e);
    if (key === 'sideOfStreetHint') {
      if (value) {
        const [lat, lng] = value.split(',').map(Number);
        points[num] = {
          ...points[num],
          sideOfStreetHint: { ...points[num].sideOfStreetHint, lat, lng },
        };
        onChange(points);
      } else if (
        // If props other then lat, lng present remove them else remove sideOfStreetHint completely
        Object.keys(omit(points[num].sideOfStreetHint, ['lat', 'lng'])).length
      ) {
        delete points[num].sideOfStreetHint.lat;
        delete points[num].sideOfStreetHint.lng;
        onChange(points);
      } else {
        delete points[num].sideOfStreetHint;
        onChange(points);
      }
    } else if (key === 'displayLocation') {
      if (value) {
        const [lat, lng] = value.split(',').map(Number);
        points[num] = {
          ...points[num],
          displayLocation: { ...points[num].displayLocation, lat, lng },
        };
        onChange(points);
      } else if (
        // If props other then lat, lng present remove them else remove displayLocation completely
        Object.keys(omit(points[num].displayLocation, ['lat', 'lng'])).length
      ) {
        delete points[num].displayLocation.lat;
        delete points[num].displayLocation.lng;
        onChange(points);
      } else {
        delete points[num].displayLocation;
        onChange(points);
      }
    } else if (key === 'matchSideOfStreet' || key === 'match') {
      if (value) {
        points[num] = {
          ...points[num],
          sideOfStreetHint: {
            ...points[num].sideOfStreetHint,
            [key]: value,
          },
        };
        onChange(points);
      } else if (
        // If props other then matchSideOfStreet present remove it else remove sideOfStreetHint completely
        Object.keys(omit(points[num].sideOfStreetHint, [key]))
          .length
      ) {
        delete points[num].sideOfStreetHint[key];
        onChange(points);
      } else {
        delete points[num].sideOfStreetHint;
        onChange(points);
      }
    } else if (key === 'extraParams') {
      const keys = Object.keys(points[num]);
      keys.forEach(key => {
        if(!options.hasOwnProperty(key) && ['lat', 'lng'].indexOf(key) < 0) {
          delete delete points[num][key];
        }
      })

      if (value !== '') {
        const extraParams = value.split(';');
        points[num] = {
          ...points[num],
          ...extraParams.reduce((p, c) => {
            const [key, value] = c.split('=');
            return { ...p, [key]: isNaN(value) ? value : +value};
          }, {}),
        };
      }
      delete points[num][key];
      
      onChange(points);
    } else {
      points[num] = {
        ...points[num],
        [key]: value,
      };
      if (value === '') {
        delete points[num][key];
      }

      onChange(points);
    }
  };

  onRemovePoint = (num) => {
    const { points, onChange } = this.props;
    points.splice(num, 1);
    onChange(points);
  };

  onAddPoint = () => {
    const { points, onChange } = this.props;
    points.push({ lat: '', lng: '' });
    onChange(points);
  };

  setPoints = (e) => {
    const { onChange } = this.props;
    const { currentPointsTab } = this.state;
    try {
      if (currentPointsTab === MultiplePoints.PointTabs.ENCODED_POLYGON) {
        decode(e.target.value);

        onChange([e.target.value], MultiplePoints.PointTabs.ENCODED_POLYGON);
      } else {
        const points = JSON.parse(e.target.value);

        if(isPolygon(points)){
          onChange(points);
        }
      }
    } catch (err) {
      // Just ignore, the textarea will be cleaned
    }
  };

  getTextAreaEl = () => {
    const { points } = this.props;
    const { currentPointsTab } = this.state;
    let pointsValue;

    if (currentPointsTab === MultiplePoints.PointTabs.ENCODED_POLYGON) {
        if (isPolygon(points)) {
          try {
            pointsValue = [];
            points.forEach(({lat, lng}) => {
              if(lat !== "NaN" && lng !== "NaN" && lat !== undefined && lng !== undefined && lat !== null && lng !== null && lat !== "" && lng !== "") {
                pointsValue.push([new BigNumber(+lat), new BigNumber(+lng), new BigNumber(0)]);
              }
            });
            pointsValue = encode(pointsValue)
            if (pointsValue !== ""){
              this.pointsCache = [pointsValue];
              
            }
          } catch (e) {
            pointsValue = "";
          }
        } else {
          pointsValue = points[0];
        }
    } else {
      if (isPolygon(points)) {
        pointsValue = JSON.stringify(points);
      } else {
        try {
          pointsValue = decode(points[0]);
          pointsValue = pointsValue.map(([lat, lng])=>{return {lat: lat.toNumber(), lng: lng.toNumber()}});
          if (isPolygon(pointsValue)){
            this.pointsCache = pointsValue;
          }
          pointsValue = JSON.stringify(pointsValue);
        } catch (e) {

        }
      }
    }
    
    
    return (
      <Textarea
        label="Points"
        value={pointsValue}
        onBlur={this.setPoints}
        isValid={isValid(points)}
      />
    );
  };

  handleSort = ({newIndex, oldIndex}) => {
    const {
      onChange,
      points
    } = this.props;
    const item = points[oldIndex];
    points.splice(oldIndex, 1);
    points.splice(newIndex, 0, item);
    onChange(points);
  }

  getPointsEl = () => {
    const { points, options } = this.props;
    let pointsValue = points;

    if (!isPolygon(points)) {
      try {
        pointsValue = decode(points[0]);
        pointsValue = pointsValue.map(([lat, lng])=>{return {lat: lat.toNumber(), lng: lng.toNumber()}});
        if (isPolygon(pointsValue)){
          this.pointsCache = pointsValue;
        }
      } catch(e){

      }
    }

    return (
      <div>
        <TabSortable 
          onSortEnd={this.handleSort}
          pointsCount={points.length}
          className="rf-nav-tabs__sortable"
          data={pointsValue}
          onBlur={this.onChangePoint}
          options={options}
          onRemovePoint={this.onRemovePoint}
          onChangeAdvanced={this.onChangeAdvanced}
        />
        <FormRow>
          <div className="rf-clearfix">
            <lui-button class="rf-add-point" onClick={this.onAddPoint}>
              Add point
            </lui-button>
          </div>
        </FormRow>
      </div>
    );
  };

  render() {
    const { currentPointsTab } = this.state;
    const { points, encoded, noTabs } = this.props;

    let tab =
      points.length > 50
        ? MultiplePoints.PointTabs.TEXT_AREA
        : currentPointsTab;

    const pointsViewEl =
      tab === MultiplePoints.PointTabs.POINTS
        ? this.getPointsEl()
        : this.getTextAreaEl();
    let tabsData = [
      { label: 'Points', name: MultiplePoints.PointTabs.POINTS },
      { label: 'Text', name: MultiplePoints.PointTabs.TEXT_AREA },
    ];
    if (encoded) {
      tabsData.push({ label: 'Encoded', name: MultiplePoints.PointTabs.ENCODED_POLYGON });
    }

    return (
      <div className="rf-multiple-points">
        {!noTabs && (<TabsControl
          tabsData={tabsData}
          currentTabName={tab}
          onChange={(e) => this.setState({ currentPointsTab: e })}
        />)}
        {pointsViewEl}
      </div>
    );
  }
}

MultiplePoints.propTypes = {
  points: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object,
};

export default MultiplePoints;
