import React from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import utils from 'utils';
import { FormRow, Input } from '@here/ref-client-ui';
import OlsWaypointAdvanced from './olsWaypointAdvanced';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { getOpenapiInfo, getOpenapiUrl } from '../openapiHelpers';
import Searchable from '../../../views/shared/searchUtils/Searchable';

class OlsWaypointView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }
  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings)
        .then((openapiInfo) => {
          if (!this.isUnmounted) {
            this.setState({ openapiInfo });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  componentDidUpdate() {
    const {
      options: { key },
      fields,
      setFields,
    } = this.props;
    const field = fields[key];
    if (!field) {
      return;
    }
    const noSpaces = field.replace(/\s/g, '');
    if (field !== noSpaces) {
      setFields({ [key]: noSpaces });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  onChange = (e) => {
    const {
      setFields,
      options: { key },
    } = this.props;
    setFields({ [key]: decodeURIComponent(utils.extractData(e)) });
  };

  onAdvancedChanged = (field, e) => {
    const {
      setFields,
      options: { key },
    } = this.props;
    setFields({ [`${key}_${field}`]: utils.extractData(e) });
  };

  render() {
    const {
      options: { key, label, advancedOptions },
      fields,
      setNotification,
    } = this.props;
    const field = fields[key];
    return (
      <Searchable searchKey={key}>
        <FormRow>
          <Input
            label={label}
            value={field}
            onBlur={this.onChange}
            tooltip={this.getTooltip()}
            tooltipPlacement="right"
            blurOnEnter
          />
        </FormRow>
        <OlsWaypointAdvanced
          fields={fields}
          data={removeAdvancedParamsPrefix(getAdvancedParams(fields, key), key)}
          options={advancedOptions}
          onChange={this.onAdvancedChanged}
          setNotification={setNotification}
        />
      </Searchable>
    );
  }
}

OlsWaypointView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

export function splitAdvancedWaypointParams(
  waypointStr = '',
  currentAdvancedParams = {},
  key
) {
  let [waypointAndPlaceOptions, ...waypointOptions] = waypointStr.split('!');
  let [coords, ...placeOptions] = waypointAndPlaceOptions.split(';');
  const advancedParams = {};

  const getParams = (delimiter, option) => {
    let [param, ...value] = option.split('=');
    value = value.join('=');
    if (currentAdvancedParams.hasOwnProperty(`${key}_${param}`)) {
      advancedParams[`${key}_${param}`] = value;
    } else {
      const extraParamsKey = `${key}_extraParams`;
      const isPlaceOptions = delimiter === ';';
      const withDelimiter = delimiter + option;
      advancedParams[extraParamsKey] = advancedParams.hasOwnProperty(extraParamsKey)
        ? (isPlaceOptions ? withDelimiter + advancedParams[extraParamsKey] : advancedParams[extraParamsKey] + withDelimiter)
        : withDelimiter;
    }
  };

  waypointOptions.forEach(getParams.bind(this, '!'));
  placeOptions.forEach(getParams.bind(this, ';'));

  return {
    coords: coords.replace(' ', ''),
    advanced: {
      ...currentAdvancedParams,
      ...advancedParams,
    },
  };
}

export function stringifyWaypointParams(data, key) {
  let placeOptionsStr = '';
  let waypointOptionsStr = '';
  let extraParamsStr = '';
  const waypointOptions = ['stopDuration', 'charging'];

  Object.keys(data.advanced).forEach((param) => {
    let value = data.advanced[param];

    param = param.replace(new RegExp(`^${key}_`), '');

    if (value === '') {
      return;
    }

    if (param === 'extraParams') {
      extraParamsStr = `${value}`;
    } else if (waypointOptions.indexOf(param) !== -1) {
      waypointOptionsStr += `!${param}=${value}`;
    } else {
      placeOptionsStr += `;${param}=${value}`;
    }
  });

  return `${data.coords}${placeOptionsStr}${extraParamsStr}${waypointOptionsStr}`;
}

export function removeAdvancedParamsPrefix(params, key) {
  return Object.keys(params).reduce((res, param) => {
    res[param.replace(new RegExp(`^${key}_`), '')] = params[param];
    return res;
  }, {});
}

export function getAdvancedParams(fields, key) {
  return pick(
    fields,
    `${key}_course`,
    `${key}_sideOfStreetHint`,
    `${key}_displayLocation`,
    `${key}_uTurnPermission`,
    `${key}_matchSideOfStreet`,
    `${key}_nameHint`,
    `${key}_radius`,
    `${key}_snapRadius`,
    `${key}_minCourseDistance`,
    `${key}_customizationIndex`,
    `${key}_stopDuration`,
    `${key}_charging`,
    `${key}_radiusPenalty`,
    `${key}_segmentIdHint`,
    `${key}_onRoadThreshold`,
    `${key}_extraParams`
  );
}

export function defaultAdvancedState(key) {
  return {
    [`${key}_course`]: ``,
    [`${key}_sideOfStreetHint`]: ``,
    [`${key}_displayLocation`]: ``,
    [`${key}_uTurnPermission`]: ``,
    [`${key}_matchSideOfStreet`]: ``,
    [`${key}_nameHint`]: ``,
    [`${key}_radius`]: ``,
    [`${key}_snapRadius`]: ``,
    [`${key}_minCourseDistance`]: ``,
    [`${key}_customizationIndex`]: ``,
    [`${key}_stopDuration`]: ``,
    [`${key}_charging`]: ``,
    [`${key}_radiusPenalty`]: ``,
    [`${key}_segmentIdHint`]: ``,
    [`${key}_onRoadThreshold`]: ``,
    [`${key}_extraParams`]: ``,
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ parsedParams, options }) => {
    const { key } = options;
    const keyLowerCased = key.toLowerCase();
    let receivedValue;
    if (isArray(parsedParams[keyLowerCased])) {
      receivedValue = parsedParams[keyLowerCased][0] || '';
    } else {
      receivedValue = parsedParams[keyLowerCased] || '';
    }
    const parsedValue = splitAdvancedWaypointParams(
      receivedValue,
      defaultAdvancedState(key),
      key
    );
    const res = { [key]: parsedValue.coords, ...parsedValue.advanced };
    delete parsedParams[keyLowerCased];
    return res;
  },

  defaultState: ({ key }) => ({
    [key]: ``,
    ...defaultAdvancedState(key),
  }),

  getRequestOptions: (fields, options) => {
    const { key } = options;
    const params = stringifyWaypointParams(
      {
        coords: fields[key],
        advanced: getAdvancedParams(fields, key),
      },
      key
    );
    if (params) {
      return { params: { [key]: params } };
    } else {
      return { params: {} };
    }
  },

  Component: OlsWaypointView,
};
