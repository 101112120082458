import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CloseButton, FormRow, Input, Select } from '@here/ref-client-ui';
import './layers.scss';

function Layers({ layers = [], segmentId = '', setFields, selectedLayers, getInspectSegmentData, setResultState }) {
  const [isNewLayer, setIsNewLayer] = useState(false);
  let enableRequest = 0;

  const isValid = (index) => {
    const { version } = selectedLayers[index];
    const isValid = (version === null || version === '' || parseInt(version) > 0);
    if (isValid) enableRequest++;
    return isValid;
  }
  const toggleNewLayer = () => {
    setIsNewLayer(!isNewLayer);
  };
  const addLayer = ({ target: { value } }) => {
    setFields({
      selectedLayers: [...selectedLayers, { layer: value, version: '' }],
    });
    toggleNewLayer();
  };
  const removeLayer = (index) => {
    setFields({
      selectedLayers: [
        ...selectedLayers.slice(0, index),
        ...selectedLayers.slice(index + 1),
      ],
    });
  };
  const changeVersion = ({ target: { value } }, index) => {
    const newLayer = { ...selectedLayers[index] };
    newLayer.version = value;
    setFields({
      selectedLayers: [
        ...selectedLayers.slice(0, index),
        newLayer,
        ...selectedLayers.slice(index + 1),
      ],
    });
  };
  const requestData = async () => {
    setFields({ isLoading: true });
    setTimeout(async () => {
      const data = await getInspectSegmentData(
        segmentId,
        layers
      );
      setFields({
        inspectSegmentData: data,
        isLoading: false,
      });
      setResultState({ isResultPanelShown: true });
    }, 0);
  }

  const selectedLayersSet = new Set();
  selectedLayers.forEach(({ layer }) => {
    selectedLayersSet.add(layer);
  });
  const filteredLayers = layers.filter(
    (layer) => !selectedLayersSet.has(layer.value)
  );

  const layerEls = selectedLayers.map(({ layer, version }, index) => (
    <div className="rf-inspect-link-layers__column" key={layer}>
      <Input value={layer} label="Layer" isReadonly />
      <Input
        value={version || ''}
        onBlur={(e) => changeVersion(e, index)}
        label="Version"
        placeholder="Version"
        isValid={isValid(index)}
        type="number"
      />
      {index !== 0 && <CloseButton onClick={() => removeLayer(index)} />}
    </div>
  ));

  return (
    <div className="rf-inspect-link-layers">
      <FormRow>
        {layerEls}
        {isNewLayer ? (
          <>
            <FormRow>
              <Select
                value=""
                options={filteredLayers}
                onChange={addLayer}
                label="Layer"
                addEmptyOption
              />
            </FormRow>
            <FormRow>
              <Button title="Cancel" onClick={toggleNewLayer} />
            </FormRow>
          </>
        ) : (
          <>
            <Button title="Add layer" onClick={toggleNewLayer} />
            <Button className="rf-button-request" title="Request Data" disabled={enableRequest<5} onClick={requestData} />
          </>
        )}
      </FormRow>
    </div>
  );
}

Layers.propTypes = {
  layers: PropTypes.array,
  setFields: PropTypes.func.isRequired,
  setResultState: PropTypes.func.isRequired,
  selectedLayers: PropTypes.array.isRequired,
};

export default Layers;
