import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormRow, Select, Tree } from '@here/ref-client-ui';
import { get } from 'lodash';
import ResultPanelErrors from '../core/views/resultPanel/ResultPanelErrors';

function getSupplyType(voltsRange, phases) {
  const currentType = (voltsRange.match(/\b(?:AC|DC)\b/) || [])[0];
  if (currentType === 'DC') {
    return 'dc';
  } else if (currentType === 'AC' && phases === 3) {
    return 'acThree';
  } else if (currentType === 'AC') {
    return 'acSingle';
  } else {
    return '';
  }
}

function setInputStyle(tabsDetail) {
  const input = document.querySelector('.route-waypoint select');
  if (input) {
    if (tabsDetail[input.value]) {
      input.style.setProperty('border-top', `0.4rem solid ${tabsDetail[input.value].tab.tabColorPalette.primary}`);
    } else {
      input.style.setProperty('border-top', '');
    }
  }
}

// function ChargingStationsResult(param) {
class ChargingStationsResult extends React.Component {
  tabsDetail = {};
  tabOptions = [{label: 'New Tab', value: 'new'}];

  componentDidUpdate() {
    setInputStyle(this.tabsDetail);
  }

  componentDidMount() {
    setInputStyle(this.tabsDetail);
  }

  onChangeTab = ({ target: { value } }) => {
    const { result = {}, setResultState } = this.props;
    const { state = {} } = result;
    const selectedTab = get(state, 'selectedTab', {});
    selectedTab.routeTab = value;
    setResultState({ selectedTab });
  }

  onChangePort = ({ target: { value } }) => {
    const { result = {}, setResultState } = this.props;
    const { state = {} } = result;
    const selectedPort = get(state, 'selectedPort', {});
    selectedPort.portId = value;
    setResultState({ selectedPort });
  }

  addVia = (ports, portId, position, routeTab) => {
    const { addTab, setFields, setSelectedTab } = this.props;
    const { maxPowerLevel, chargingPoint: { ampsRange, voltsRange, phases } } = ports[parseInt(portId)];
    const { lng, lat } = position;
    const { tab } = this.tabsDetail[routeTab] || {};
    const { fields } = tab || {};
    const { via } = fields || {};
    const supplyType = getSupplyType(voltsRange, phases);
    const amp = parseInt(ampsRange);
    const voltsMatches = voltsRange.match(/\d+/g);
    const newVia = {
      coords:  `${lat},${lng}`, 
      advanced: {
        charging: `(power=${maxPowerLevel*1000};current=${amp};voltage=${voltsMatches[0]};supplyType=${supplyType};minDuration=900;maxDuration=3600)`
      }
    };
    const waypointFields = {
      via: [ ...(via || []), newVia]
    };

    // selected 'new tab' or selected tab is removed
    if (routeTab === 'new' || !this.tabsDetail.hasOwnProperty(routeTab)) {
      addTab({ title: 'Routing V8', fields: waypointFields });
    } else {
      const { index } = this.tabsDetail[routeTab];
      setFields(waypointFields, parseInt(index));
      setSelectedTab(parseInt(index));
    }
  }

  addExclude = (id, routeTab) => {
    const key = 'ev[excludeChargingStations]';
    const { addTab, setFields, setSelectedTab } = this.props;
    const { tab } = this.tabsDetail[routeTab] || {};
    const { fields } = tab || {};
    const identifier = id.replace(/^here:pds:place:/, "")
    // selected 'new tab' or selected tab is removed
    if (routeTab === 'new' || !this.tabsDetail.hasOwnProperty(routeTab)) {
      const newExcludes = {
        [key]: identifier,
        evEnabled: true
      };
      addTab({ title: 'Routing V8', fields: newExcludes });
    } else {
      const excludes = (fields || {})[key].split(',').filter(id => id);
      if ( excludes.indexOf(identifier) === -1 ) {
        excludes.push(identifier);
      }
      const excludesFields = {
        [key]: excludes.join(',')
      };
      const { index } = this.tabsDetail[routeTab];
      setFields(excludesFields, parseInt(index));
      setSelectedTab(parseInt(index));
    }
  }

  render(){
    const { result = {}, tabs } = this.props;
    const { state = {} } = result;
    const { selectedStation, selectedTab = {}, selectedPort = {}, error } = state;

    if (error) {
      return <ResultPanelErrors response={error} />;
    }

    if (!selectedStation) {
      return <h4>Please select charging station</h4>;
    }

    const connectors = get(selectedStation, 'extended.evStation.connectors', []);

    let { routeTab = 'new' } = selectedTab;
    let routingTabExists = false;
    let { portId = '0' } = selectedPort;
    
    const { position, id } = selectedStation;


    const routingTabs = tabs.map((tab, index) => {
      if (tab.title === 'Routing V8') {
        this.tabsDetail[tab.id] = {tab, index};
        if ( ''+tab.id === routeTab ) {
          routingTabExists = true;
        }
        return {label: tab.tabTitle, value: tab.id+''} 
      } else return null
    }).filter(item => item);
    routeTab = routingTabExists ? routeTab : 'new';

    const chargingConnectors = connectors.map(
      ({maxPowerLevel}, index) => {
        return {label: `Charging connector ${index+1} (${maxPowerLevel} kW)`, value: index+''}
      }
    );
    portId = portId >= chargingConnectors.length ? '0' : portId;
    return (
      <>
        <h4>Selected charging station:</h4>
        <Tree data={selectedStation} shouldExpandNode={(keyPath, data, level) => keyPath.indexOf('extended') > -1 && level < 4} />
        <div>
          <h4>Add Charging station to Routing:</h4>
          {chargingConnectors.length > 1 && <FormRow className="charging-station-port">
            <Select
              options={chargingConnectors}
              value={portId}
              onChange={this.onChangePort}
            />
          </FormRow> }
          <FormRow className="route-waypoint">
            <Select
              options={this.tabOptions.concat(routingTabs)}
              value={routeTab}
              onChange={this.onChangeTab}
            />
          </FormRow>
          <Button className = "routeto" onClick={() => this.addVia(connectors, portId, position, routeTab)} title="Add to Via point" />
          <Button className = "routeto" onClick={() => this.addExclude(id, routeTab)} title="Exclude in Routing" />
        </div>
      </>
    );
  }
}

ChargingStationsResult.propTypes = {
  result: PropTypes.object,
};

export default ChargingStationsResult;
