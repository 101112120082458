const defaultConnectorTypes = {
  'ev[connectorTypes]': [
    'iec62196Type1Combo',
    'iec62196Type2Combo',
    'Chademo',
    'Tesla',
    'gbtDc',
  ],
};

export const empty = {
  evEnabled: false,
  evPost: {},

  // Consumption Parameters
  'ev[freeFlowSpeedTable]': '',
  'ev[trafficSpeedTable]': '',
  'ev[auxiliaryConsumption]': '',
  'ev[ascent]': '',
  'ev[descent]': '',
  'ev[acceleration]': '',
  'ev[deceleration]': '',
  'ev[highSpeedThreshold]': '',

  // Battery Parameters
  'ev[initialCharge]': '',
  'ev[maxCharge]': '',
  'ev[chargingCurve]': '',
  'ev[maxChargingVoltage]': '',
  'ev[maxChargingCurrent]': '',

  // Charging Parameters
  'ev[maxChargeAfterChargingStation]': '',
  'ev[minChargeAtChargingStation]': '',
  'ev[minChargeAtFirstChargingStation]': '',
  'ev[minChargeAtDestination]': '',
  'ev[chargingSetupDuration]': '',

  // Enable ECAR
  'ev[makeReachable]': '',
  'ev[connectorTypes]': [],
  'ev[preferredBrands]': '',
  'ev[preferredChargePointOperators]': '',
  'ev[eMobilityServiceProviderPreferences]': '',
  'ev[excludeChargingStations]': '',

  // Consumption Model
  'consumptionModel': '',
  'ev[auxiliaryPowerConsumption]': '',
  'ev[recuperationEfficiency]': '',
  'ev[driveEfficiency]': '',
};

export const modelDefault = {
  label: 'Model Default',
  value: 'default',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'ev[freeFlowSpeedTable]':
      '10,0.130,20,0.132,30,0.136,40,0.142,50,0.151,60,0.161,70,0.176,80,0.194,90,0.214,100,0.237,110,0.264,120,0.290,130,0.321,140,0.354,150,0.389,160,0.426,170,0.466,180,0.509,190,0.554,200,0.601',
    // eslint-disable-next-line max-len
    'ev[trafficSpeedTable]':
      '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
    'ev[auxiliaryConsumption]': '1.8',
    'ev[ascent]': '9',
    'ev[descent]': '4.3',
    'ev[acceleration]': '',
    'ev[deceleration]': '',
    'ev[highSpeedThreshold]': '',

    // Battery Parameters
    'ev[initialCharge]': '',
    'ev[maxCharge]': '',
    'ev[chargingCurve]': '',
    'ev[maxChargingVoltage]': '400',
    'ev[maxChargingCurrent]': '',

    // Charging Parameters
    'ev[maxChargeAfterChargingStation]': '',
    'ev[minChargeAtChargingStation]': '',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '',
    'ev[chargingSetupDuration]': '300',

    // Enable ECAR
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': '',
    'ev[auxiliaryPowerConsumption]': '',
    'ev[recuperationEfficiency]': '',
    'ev[driveEfficiency]': '',

    'transportMode': 'car',
    'vehicle[currentWeight]': '',
    'vehicle[airDragCoefficient]': '',
    'vehicle[rollingResistanceCoefficient]': '',
    'vehicle[frontalArea]': '',
  },
};

export const preset50kWh = {
  label: '50kWh Car',
  value: '50kwh',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'ev[freeFlowSpeedTable]':
      '10,0.130,20,0.132,30,0.136,40,0.142,50,0.151,60,0.161,70,0.176,80,0.194,90,0.214,100,0.237,110,0.264,120,0.290,130,0.321,140,0.354,150,0.389,160,0.426,170,0.466,180,0.509,190,0.554,200,0.601',
    // eslint-disable-next-line max-len
    'ev[trafficSpeedTable]':
      '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
    'ev[auxiliaryConsumption]': '1.8',
    'ev[ascent]': '9',
    'ev[descent]': '4.3',
    'ev[acceleration]': '',
    'ev[deceleration]': '',
    'ev[highSpeedThreshold]': '',

    // Battery Parameters
    'ev[initialCharge]': '35',
    'ev[maxCharge]': '50',
    'ev[chargingCurve]':
      '0,96,5,193,10,200,20,190,30,96,40,48,50,5',
    'ev[maxChargingVoltage]': '400',
    'ev[maxChargingCurrent]': '',

    // Charging Parameters
    'ev[maxChargeAfterChargingStation]': '45',
    'ev[minChargeAtChargingStation]': '5',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '5',
    'ev[chargingSetupDuration]': '300',

    // Enable ECAR
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': '',
    'ev[auxiliaryPowerConsumption]': '',
    'ev[recuperationEfficiency]': '',
    'ev[driveEfficiency]': '',

    'transportMode': 'car',
    'vehicle[currentWeight]': '',
    'vehicle[airDragCoefficient]': '',
    'vehicle[rollingResistanceCoefficient]': '',
    'vehicle[frontalArea]': '',
  },
};

export const preset80kWh = {
  label: '80kWh Car',
  value: '80kwh',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'ev[freeFlowSpeedTable]':
      '10,0.130,20,0.132,30,0.136,40,0.142,50,0.151,60,0.161,70,0.176,80,0.194,90,0.214,100,0.237,110,0.264,120,0.290,130,0.321,140,0.354,150,0.389,160,0.426,170,0.466,180,0.509,190,0.554,200,0.601',
    // eslint-disable-next-line max-len
    'ev[trafficSpeedTable]':
      '0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36',
    'ev[auxiliaryConsumption]': '1.8',
    'ev[ascent]': '9',
    'ev[descent]': '4.3',
    'ev[acceleration]': '',
    'ev[deceleration]': '',
    'ev[highSpeedThreshold]': '',

    // Battery Parameters
    'ev[initialCharge]': '48',
    'ev[maxCharge]': '80',
    'ev[chargingCurve]':
      '0,96,8,193,16,200,32,190,48,96,64,48,80,5',
    'ev[maxChargingVoltage]': '400',
    'ev[maxChargingCurrent]': '',

    // Charging Parameters
    'ev[maxChargeAfterChargingStation]': '72',
    'ev[minChargeAtChargingStation]': '8',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '8',
    'ev[chargingSetupDuration]': '300',

    // Enable ECAR
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': '',
    'ev[auxiliaryPowerConsumption]': '',
    'ev[recuperationEfficiency]': '',
    'ev[driveEfficiency]': '',

    'transportMode': 'car',
    'vehicle[currentWeight]': '',
    'vehicle[airDragCoefficient]': '',
    'vehicle[rollingResistanceCoefficient]': '',
    'vehicle[frontalArea]': '',
  },
};

export const presetMotorcycle = {
  label: '15kWh Motorcycle',
  value: 'motorcycle',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'ev[freeFlowSpeedTable]':
      '0,0.043,27,0.043,45,0.038,60,0.032,75,0.036,90,0.047,100,0.053,110,0.061,120,0.065,130,0.073,250,0.094',
    // eslint-disable-next-line max-len
    'ev[trafficSpeedTable]':
      '0,0.053,27,0.053,45,0.048,60,0.042,75,0.046,90,0.057,100,0.063,110,0.071,120,0.075,130,0.083,250,0.104',
    'ev[auxiliaryConsumption]': '0.2',
    'ev[ascent]': '1.5',
    'ev[descent]': '0.7',
    'ev[acceleration]': '',
    'ev[deceleration]': '',
    'ev[highSpeedThreshold]': '',

    // Battery Parameters
    'ev[initialCharge]': '10.2',
    'ev[maxCharge]': '15',
    'ev[chargingCurve]': '0,35,3,34,6,32,9,28,10,26,12,20,14,10,15,5,16,0.1',
    'ev[maxChargingVoltage]': '400',
    'ev[maxChargingCurrent]': '125',

    // Charging Parameters
    'ev[maxChargeAfterChargingStation]': '14',
    'ev[minChargeAtChargingStation]': '1.5',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '1.5',
    'ev[chargingSetupDuration]': '300',

    // Enable ECAR
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': '',
    'ev[auxiliaryPowerConsumption]': '',
    'ev[recuperationEfficiency]': '',
    'ev[driveEfficiency]': '',

    'transportMode': 'car',
    'vehicle[currentWeight]': '',
    'vehicle[airDragCoefficient]': '',
    'vehicle[rollingResistanceCoefficient]': '',
    'vehicle[frontalArea]': '',
  },
};

export const truckPreset = {
  label: '300kWh Truck',
  value: 'truck300',
  preset: {
    ...empty,
    evEnabled: true,
    'ev[freeFlowSpeedTable]':
      '0,0.66,27,0.66,45,0.72,60,0.54,75,0.575,90,0.661,100,0.72,110,0.82,120,0.936,130,0.975,250,1.175',
    'ev[trafficSpeedTable]':
      '0,0.76,27,0.76,45,0.82,60,0.61,75,0.645,90,0.761,100,0.82,110,0.97,120,1.136,130,1.175,250,1.175',
    'ev[auxiliaryConsumption]': '5.2',
    'ev[ascent]': '25',
    'ev[descent]': '11',
    'ev[initialCharge]': '180',
    'ev[maxCharge]': '300',
    'ev[chargingCurve]':
      '0,239,120,199,210,167,225,130,240,111,255,83,270,55,285,33,293,17,300,1',
    'ev[maxChargeAfterChargingStation]': '270',
    'ev[minChargeAtChargingStation]': '30',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '30',
    'ev[chargingSetupDuration]': '300',
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': '',
    'ev[auxiliaryPowerConsumption]': '',
    'ev[recuperationEfficiency]': '',
    'ev[driveEfficiency]': '',

    'transportMode': 'car',
    'vehicle[currentWeight]': '',
    'vehicle[airDragCoefficient]': '',
    'vehicle[rollingResistanceCoefficient]': '',
    'vehicle[frontalArea]': '',
  },
};

export const car80kWhPhysicalPreset = {
  label: '80kWh Car (Physical)',
  value: '80kwhPhysical',
  preset: {
    evEnabled: true,

    // Consumption Parameters
    // eslint-disable-next-line max-len
    'ev[freeFlowSpeedTable]': '',
    // eslint-disable-next-line max-len
    'ev[trafficSpeedTable]': '',
    'ev[auxiliaryConsumption]': '',
    'ev[ascent]': '',
    'ev[descent]': '',
    'ev[acceleration]': '',
    'ev[deceleration]': '',
    'ev[highSpeedThreshold]': '',

    // Battery Parameters
    'ev[initialCharge]': '48',
    'ev[maxCharge]': '80',
    'ev[chargingCurve]':
      '0,96,8,193,16,200,32,190,48,96,64,48,80,5',
    'ev[maxChargingVoltage]': '400',
    'ev[maxChargingCurrent]': '',

    // Charging Parameters
    'ev[maxChargeAfterChargingStation]': '72',
    'ev[minChargeAtChargingStation]': '8',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '8',
    'ev[chargingSetupDuration]': '300',

    // Enable ECAR
    'ev[makeReachable]': 'true',
    ...defaultConnectorTypes,

    // Consumption Model
    'consumptionModel': 'physical',
    'ev[auxiliaryPowerConsumption]': '100',
    'ev[recuperationEfficiency]': '0.7',
    'ev[driveEfficiency]': '0.9',

    'transportMode': 'car',
    'vehicle[currentWeight]': '2100',
    'vehicle[airDragCoefficient]': '0.28',
    'vehicle[rollingResistanceCoefficient]': '0.02',
    'vehicle[frontalArea]': '3.1',
  },
};

export const truckPhysicalPreset = {
  label: '20t Truck (physical)',
  value: 'truckPhysical',
  preset: {
    ...empty,
    evEnabled: true,
    'ev[freeFlowSpeedTable]': '',
    'ev[trafficSpeedTable]': '',
    'ev[ascent]': '',
    'ev[descent]': '',
    'ev[initialCharge]': '',
    'ev[maxCharge]': '',
    'ev[chargingCurve]': '',
    'ev[maxChargeAfterChargingStation]': '',
    'ev[minChargeAtChargingStation]': '',
    'ev[minChargeAtFirstChargingStation]': '',
    'ev[minChargeAtDestination]': '',
    'ev[chargingSetupDuration]': '',
    'ev[makeReachable]': 'false',
    'ev[connectorTypes]': [],

    // Consumption Model physical
    'consumptionModel': 'physical',
    'ev[auxiliaryPowerConsumption]': '3600',
    'ev[recuperationEfficiency]': '0.8',
    'ev[driveEfficiency]': '0.9',

    'transportMode': 'truck',
    'vehicle[currentWeight]': '20000',
    'vehicle[airDragCoefficient]': '0.7',
    'vehicle[rollingResistanceCoefficient]': '0.0066',
    'vehicle[frontalArea]': '10',
  },
};

const electricPresets = [
  modelDefault,
  preset50kWh,
  preset80kWh,
  presetMotorcycle,
  truckPreset,
  car80kWhPhysicalPreset,
  truckPhysicalPreset,
];

export default electricPresets;
