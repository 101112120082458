import React from 'react';
import PropTypes from 'prop-types';
import HereRect from '../../../views/shared/hereMap/HereRect';
import { addOpacityToHexColor } from '../../utils';
import HereGroup from '../../../views/shared/hereMap/HereGroup';
import HereMapContextMenuItem from '../../../views/shared/hereMap/HereContextMenuItem';

class Rect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  onChange = (bounds) => {
    const { onChange } = this.props;
    const toFixed = (num) => +num.toFixed(6);
    onChange([
      [toFixed(bounds.getTop()), toFixed(bounds.getRight())],
      [toFixed(bounds.getBottom()), toFixed(bounds.getLeft())],
    ]);
  };

  onContextMenu = (e) => {
    const { map } = this.props;
    
    e.stopPropagation();
    const {
      originalEvent: { x, y },
      viewportX,
      viewportY
    } = e;
    this.setState({
      open: true,
      position: {
        top: y,
        left: x,
        x: viewportX,
        y: viewportY
      },
    });

    window.addEventListener('click', this.close);
    map.addEventListener('mapviewchangestart', this.close);
  }

  close = () => {
    const { map } = this.props;
    if (!this.isUnmounted) {
      this.setState({ open: false });
    }
    window.removeEventListener('click', this.close);
    map.removeEventListener('mapviewchangestart', this.close);
  };

  addException = (type, e, v) => {
    const { onContextMenu, map } = this.props;
    const { position: { x, y } } = this.state;
    const xyToLatLng = ({ x, y }) => map.screenToGeo(x, y);

    onContextMenu(type, e, {
      latLng: xyToLatLng({x, y}),
      xy: { x, y },
      xyToLatLng,
      zoom: map.getZoom()
    });
  }

  remove = (type, e) => {
    const { onContextMenu } = this.props;
    onContextMenu(type, e);
  }

  render() {
    const { noException, skipCorridor, bounds, color, fillColor, draggable, resizable, onclick, isException, contextMenu } = this.props;

    const [[lat1, lng1], [lat2, lng2]] = bounds;
    let style = {};
    if (color) {
      style.strokeColor = color;
      style.fillColor = fillColor || addOpacityToHexColor(color, 0.5);
    }
    return (
      <>
        <HereGroup options={{ volatility: true }}>
          <HereRect
            latLngBounds={[
              { lat: lat1, lng: lng1 },
              { lat: lat2, lng: lng2 },
            ]}
            options={{ style }}
            onChange={this.onChange}
            draggable={draggable}
            resizable={resizable}
            ontap={onclick || (() => {})}
            onContextMenu={contextMenu?this.onContextMenu:null}
          />        
        </HereGroup>
        {this.state.open && (<div className="rf-context-menu" style={{ ...this.state.position }}>
          {!noException && (<HereMapContextMenuItem
            label={`Add Exception Rectangle`}
            onClick={this.addException.bind(this, 'bbox')}
          />)}
          {!noException && (<HereMapContextMenuItem
            label={`Add Exception Polygon`}
            onClick={this.addException.bind(this, 'polygon')}
          />)}
          {!noException && !skipCorridor && (<HereMapContextMenuItem
            label={`Add Exception Corridor`}
            onClick={this.addException.bind(this, 'corridor')}
          />)}
          <HereMapContextMenuItem
            label={`Remove${isException ? ' Exception' : ''}`}
            onClick={this.remove.bind(this, 'remove')}
          />
        </div>
        )}
      </>
    );
  }
}

Rect.propTypes = {
  bounds: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  fillColor: PropTypes.string,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  onclick: PropTypes.func,
};

export default Rect;
