import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './taggedManeuvers.scss';
import get from 'lodash/get';
import { Button } from '@here/ref-client-ui';

class TaggedManeuvers extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      setResultState,
      result: { raw, state },
      options: { selectedManeuver, selectedManeuverResult },
    } = this.props;
    
    const { result: { raw: prevRaw } } = prevProps;

    if (!!raw && !prevRaw) {
      setResultState({ [selectedManeuver]: null });
    }
    if ( state[selectedManeuverResult] !== null
      && state[selectedManeuver] !== null
      && state[selectedManeuver] !== prevProps.result.state[selectedManeuver]
      && state[selectedManeuverResult] === prevProps.result.state[selectedManeuverResult]
    ) {
      const element = document.getElementsByClassName('rf-tagged-maneuvers');
      element.length && element[state[selectedManeuver] || 0].scrollIntoView();
    }
  }

  getManeuverElement(maneuver, index, selected) {
    const isSelected = selected === index;
    const listItemClasses = classnames('rf-tagged-maneuvers', {
      'rf-tagged-maneuvers_selected': isSelected,
    });
    return (
      <li
        className={listItemClasses}
        onClick={this.onClick.bind(this, maneuver, index, selected)}
        key={`${maneuver.type}${index}`}
      >
        <div className="rf-tagged-maneuvers__inner">
          <div className="rf-tagged-maneuvers__content">{index+". "+maneuver.properties.hereProperties.name}</div>
        </div>
        {isSelected && this.getDetails(maneuver)}
      </li>
    );
  }

  getDetails(maneuver) {
    const { properties } = maneuver;
    if (!properties) {
      return null;
    }
    const detailsEl = [];
    Object.keys(properties).forEach((key, i) => {
        if (key === 'hereProperties') {
          Object.keys(properties.hereProperties).forEach((hereKey, i) => {
            const v = properties.hereProperties[hereKey]+'';
            detailsEl.push(
              <div key={'here'+ hereKey + i} className={`rf-maneuvers-here-properties`}>
                {hereKey}: { v === '' ? '""': v}
              </div>
            )
          })
        } else {
          const v = properties[key]+'';
          detailsEl.push(
            <div key={key + i} className={`rf-maneuvers-${key}`}>
              {key}: { v === '' ? '""': v}
            </div>
          )
        }
    });
    return <div className="rf-tagged-maneuvers__details">{detailsEl}</div>;
  }

  onClick = (maneuver, index) => {
    const {
      setResultState,
      options: { selectedManeuver, selectedManeuverResult },
      result: { state },
    } = this.props;
    const selected = get(state, selectedManeuver, null);
    if (selected === index) {
      setResultState({ [selectedManeuver]: null, [selectedManeuverResult]: null });
    } else {
      setResultState({ [selectedManeuver]: index, [selectedManeuverResult]: index });
    }
  };

  selectManeuver = (dir) => {
    const {
      setResultState,
      options: { selectedManeuver, maneuvers, selectedManeuverResult },
      result: { state },
    } = this.props;
    const selected = get(state, selectedManeuver);
    const allManeuvers = get(state, maneuvers, 0);
    if (selected === null) {
      setResultState({ [selectedManeuver]: 0, [selectedManeuverResult]: 0});
    } else if (dir === 'prev') {
      const pos = Math.max(0, selected - 1)
      setResultState({ [selectedManeuver]: pos});
    } else {
      const pos = Math.min(allManeuvers.length-1, selected + 1)
      setResultState({ [selectedManeuver]: pos});
    }
  }

  render() {
    const {
      options: { selectedManeuver, maneuvers },
      result: { state },
    } = this.props;

    const allManeuvers = get(state, maneuvers, []);
    
    if (allManeuvers.length === 0) {
      return (
        <div className="rf-tagged-maneuvers-empty">
          No results <br />
        </div>
      );
    }
    const selected = get(state, selectedManeuver, null);

    const maneuversEl = allManeuvers.map((maneuver, index) =>
      this.getManeuverElement(maneuver, index, selected)
    );

    return <div>
      <ul>{maneuversEl}</ul>
      <Button className = "rf-prev-maneuver" onClick={() => this.selectManeuver('prev')} title="Prev" />
      <Button className = "rf-next-maneuver" onClick={() => this.selectManeuver('next')} title="Next" />
    </div>;
  }
}

TaggedManeuvers.propTypes = {
  result: PropTypes.object,
  options: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
  refClient: PropTypes.object.isRequired,
};

export default TaggedManeuvers;
