import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, Button, Textarea, Select } from '@here/ref-client-ui';
import set from 'lodash/set';
import get from 'lodash/get';
import presets from './presets';
import './traceFormView.scss';
import { getOpenapiUrl, getOpenapiInfo } from '../openapiHelpers';
import Searchable from '../../../views/shared/searchUtils/Searchable';

const routeTracePreset = [
  {label: 'Sheffield (CSV)', value: 'csv'},
  {label: 'Rome (GPX)', value: 'gpx'},
  {label: 'Frankfurt (KML)', value: 'kml'},
  {label: 'MTK (GeoJson)', value: 'geojson'}
];

class TraceFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openapiInfo: null,
    };
  }

  componentDidMount() {
    const {
      options: { openapiInfo },
      settings,
      fields: { customizations }
    } = this.props;

    if (openapiInfo) {
      const { urlPath, urlRegex, urlSuffix, keys } = openapiInfo;
      const propsUrl = get(this.props, urlPath);
      const url = getOpenapiUrl(propsUrl, urlRegex, urlSuffix);
      getOpenapiInfo(url, keys, customizations, settings).then((openapiInfo) => {
        if (!this.isUnmounted) {
          this.setState({ openapiInfo });
        }
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  reset = () => {
    const {
      setFields,
      options: { key },
      request
    } = this.props;
    setFields({ [key]: '' });
    this.setState({preset: ''});
    request();
  }

  onPresetChange = (preset) => {
    const {
      setFields,
      options: { key },
      request
    } = this.props;
    const value = preset.target ? preset.target.value : preset;
    setFields({ [key]: presets[value] || '' });
    this.setState({preset: value});
    request();
  }

  onChange = ({ target: { value } }) => {
    const {
      setFields,
      options: { key },
      request,
    } = this.props;

    setFields({ [key]: value });
    request();
  };

  getTooltip = () => {
    const {
      options: { tooltip },
    } = this.props;
    let tooltipEl = null;
    const { openapiInfo } = this.state;
    if (tooltip || openapiInfo) {
      tooltipEl = (
        <div className="rf-form-view__tooltip">{tooltip || openapiInfo}</div>
      );
    }

    return tooltipEl;
  };

  dragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'copy';
  }

  drop = (evt) => {
    const {
      setFields,
      options: { key },
    } = this.props;

    evt.stopPropagation();
    evt.preventDefault();
    var files = evt.dataTransfer.files;
    var file = files[0];
    var r = new FileReader();
    r.onload = (e) => { 
      evt.target.value = r.result;
      this.setState({preset: ''});
      setFields({ [key]: r.result });
    }
    r.readAsText(file);
    
  }

  
  render() {
    const {
      options: { key, label, inputType, placeholder },
      fields,
    } = this.props;
    const { preset } = this.state;
    return (
      <Searchable searchKey={key}>
        <div
          onDrop={this.drop}
          onDragOver={this.dragOver}
        >
          <FormRow className="rf-form-view">
            <Textarea
              value={fields[key]}
              label={label}
              onBlur={this.onChange}
              placeholder={placeholder || label}
              type={inputType || 'text'}
              tooltip={this.getTooltip()}
            />
          </FormRow>
        </div>
        <FormRow>
          <Select
            label="Route Trace Preset"
            options={routeTracePreset}
            value={preset||''}
            onChange={this.onPresetChange}
            addEmptyOption
          />
        </FormRow>
        <FormRow>
          <Button
            title="Reset"
            onClick={this.reset}
          />
        </FormRow>
      </Searchable>
    );
  }
}

TraceFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({ options, postData }) => {
    const { key } = options;
    return { [key]: postData[key]};
  },
  defaultState: (options) => ({
    [options.key]: options.value || '',
  }),
  getRequestOptions: (fields, options) => {
    const { key } = options;
    const data = {[key]: ''};
    if (fields[key] !== '' && fields[key] != null) {
      set(data, key, fields[key]);
    }
    
    return {
      method: 'post',
      formData: key,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      data,
    };
  },
  Component: TraceFormView,
};
