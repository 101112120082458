import React from 'react';
import PropTypes from 'prop-types';
import HereRoute from '../routes/HereRoute';
import get from 'lodash/get';
import { decode } from 'ref-client-core/utils/flexPolyline';
import { withHereMap } from '../../../views/shared/hereMap/HereMapContext';
import { getCenterFromPolyline, getBBoxFromPolyline } from '../route/helpers';

function getPolyline(polyline, geojson) {
  if (geojson) {
    return polyline.map((latLng) => ({
      lat: +latLng[1],
      lng: +latLng[0],
    }));
  } else {
    return decode(polyline).map((latLng) => ({
      lat: +latLng[0],
      lng: +latLng[1],
    }));
  }
}

class HerePolylines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isUnmounted = false;
  }

  componentDidUpdate(prevProps) {
    const { 
      options: { selectedPath, routesArrayPath, pathPath, geojson },
      map
    } = this.props;
    const selected = get(this.props, selectedPath, null);
    const prevSelected = get(prevProps, selectedPath, null);
    
    if ( selected !== null && selected !== prevSelected ) {
      const polylines = get(this.props, routesArrayPath);
      const positions = getPolyline(get(polylines[selected], pathPath), geojson);
      const bounds = getBBoxFromPolyline(positions);

      const viewModal = map.getViewModel();
      if (viewModal.getLookAtData().bounds.getBoundingBox().containsRect(bounds)) {
        map.setCenter(getCenterFromPolyline(positions), true);
      } else {
        viewModal.setLookAtData({
          bounds: bounds
        });
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  selectPoly = (id) => {
    const { 
      setResultState,
      options: { selectedPath }
    } = this.props;
    const path = selectedPath.split('.')[2];
    const selected = get(this.props, selectedPath, null);
    
    setResultState({ [path]: selected === id ? null : id });
  };

  renderPolylines = (paths, colorPalette, type) => {
    const {
      options: { selectedPath, color, selectedArrow },
    } = this.props;
    const selected = get(this.props, selectedPath, -1);
    const polylines = [];
    paths.forEach((path, idx) => {
      const { positions, parent } = path;
      if ( parent ) {
        const { approachPathIndex, spanIndex } = parent;
        const parentPath = paths[approachPathIndex];
        const { spans, positions: parentPositions } = parentPath;
        const firstPoint = parentPositions[spans[spanIndex].offset];
        positions.push(firstPoint);
      }
      const key = `${idx}`;

      if (idx === selected) {
        polylines.push(
          <HereRoute
            changeCursorOnHover
            onClick={() => this.selectPoly(idx)}
            key={key}
            positions={positions}
            color={colorPalette[color+'Darker']}
            weight={8}
            zIndex={30}
            type={(selectedArrow && type.indexOf('raster') > -1) ? 'rasterArrow' : ''}
          />
        );
        if (selectedArrow && type.indexOf('vector') > -1) {
          polylines.push(
            <HereRoute
              changeCursorOnHover
              onClick={() => this.selectPoly(idx)}
              key={key+'arrow'}
              positions={positions}
              color={colorPalette[color+'Darker']}
              weight={8}
              zIndex={30}
              type={'vectorArrow'}
            />);
        }
      } else {
        polylines.push(
          <HereRoute
            changeCursorOnHover
            onClick={() => this.selectPoly(idx)}
            key={key}
            positions={positions}
            color={colorPalette[color]}
            weight={6}
            zIndex={10}
          />
        );
      }
    });
    return [...polylines];
  };

  render() {
    const {
      options,
      colorPalette,
      type
    } = this.props;
    const {
      routesArrayPath,
      pathPath,
      geojson
    } = options;
    const polylines = get(this.props, routesArrayPath);
    if (!polylines) {
      return null;
    }

    const decodedPolylines = polylines.map(polyline => {
      const positions = getPolyline(get(polyline, pathPath), geojson);
      return { ...polyline, positions }
    });
    return (
      <>
        {this.renderPolylines(
          decodedPolylines,
          colorPalette,
          type
        )}
      </>
    );
  }
}

HerePolylines.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.object,
  result: PropTypes.object,
  colorPalette: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
  refClient: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default withHereMap(HerePolylines);
