import { createSelector } from 'reselect';
import { getFields } from '../tabs/tab/fields/reducer';
import formViewsPlugin from '../../core/formViewsPlugin';
import { getCurrentConfig } from '../config';
import { merge } from 'lodash';
import { logError } from '../../utils/splunkLog';
import refClient from '../../core/refClient';

export default createSelector(
  (state, tabIndex) => getCurrentConfig(state, tabIndex),
  (state, tabIndex) => getFields(state, tabIndex),
  buildParams
);

function buildParams(config, fields) {
  if (!config || config.hideUrlParams) {
    return {};
  }
  return config.inputs.reduce((acc, input) => {
    try {
      const formFields = formViewsPlugin
        .get(input.type)
        .getRequestOptions(fields, input.options, refClient);
      return merge(acc, formFields);
    } catch (e) {
      logError({...e, input});
      return acc;
    }
  }, {});
}
