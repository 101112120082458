import React from 'react';
import PropTypes from 'prop-types';
import './extraParamsWaypoint.scss';
import { ExpandFormRow, FormRow, CloseButton, Input, Select, Columns, Button} from '@here/ref-client-ui';

const params = [
  { label: '! (Waypoint Params)', value: '!'},
  { label: '; (Place Params)', value: ';'}
];


class ExtraParamsWaypoint extends React.Component {
  onChange = (i, delimiter, entry, type, {target: {value}}) => {
    const { params, onChange } = this.props;

    const [delimiterPlaceOptions, ...waypointOptions] = params.split('!');
    const [, ...placeOptions] = delimiterPlaceOptions.split(';');

    if (type === 'param') {
      if (delimiter === '!') {
        waypointOptions[i] = value;
      } else {
        placeOptions[i] = value;
      }
    } else {
      if (delimiter === '!') {
        waypointOptions.splice(i, 1);
      } else {
        placeOptions.splice(i, 1)
      }
    }

    const waypointParams = waypointOptions.reduce((accumulator, currentValue) => accumulator + '!' + currentValue, '');
    const placeParams = placeOptions.reduce((accumulator, currentValue) => accumulator + ';' + currentValue, '');
    onChange(placeParams + (type==='delimiter'?(value+entry):'') + waypointParams);
  }
  
  onAddParameter = () => {
    const { onChange, params } = this.props;
    onChange(params+'!');
  };

  getEle = (extraParams) => {
    if ( !extraParams ) return <></>;

    let [delimiterPlaceOptions, ...waypointOptions] = extraParams.split('!');
    let [, ...placeOptions] = delimiterPlaceOptions.split(';');

    const getEntry = (delimiter, entry, i) => {
      return <Columns key={i+'extraParams'}>
          <Select
              className={'rf-extra-params-delimiter'}
              label='Delimiter'
              options={params}
              value={delimiter}
              onChange={this.onChange.bind(this, i, delimiter, entry, 'delimiter')}
          />
          <Input
              className={'rf-extra-params-value'}
              label='Extra Parameter'
              placeholder='Extra Parameter'
              value={entry}
              onBlur={this.onChange.bind(this, i, delimiter, entry, 'param')}
              blurOnEnter
          />
          <div className={'rf-extra-params-place-holder'}/>
          <CloseButton className="rf-extra-params-close-button" onClick={this.onChange.bind(this, i, delimiter, entry, 'close')} />
      </Columns>
    }
    // const advancedOptions = [...placeOptions, ...waypointOptions];
    const placeOptionsEntries = placeOptions.map(getEntry.bind(this, ';'));
    const waypointOptionsEntries = waypointOptions.map(getEntry.bind(this, '!'));
    return (
      <FormRow key={'param.key'}>
          {placeOptionsEntries}
          {waypointOptionsEntries}
      </FormRow>
    )
  }

  render() {
    const { params } = this.props;
    const pointsViewEl = this.getEle(params);

    return (
      <ExpandFormRow
        className="rf-advanced-sub-params"
        label="Extra Parameters"
      >
        {pointsViewEl}
        <FormRow>
          <Button
            title="Add Extra Parameter"
            onClick={this.onAddParameter}
          />
        </FormRow>
      </ExpandFormRow>
    );
  }
}

ExtraParamsWaypoint.propTypes = {
  onChange: PropTypes.func.isRequired,
  params: PropTypes.string,
};

export default ExtraParamsWaypoint;
