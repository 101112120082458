import { Icon } from 'leaflet';
import { useRef } from 'react';
import { MarkerIcon } from '@here/ref-client-ui';
import { TILE_LIBRARY_TYPES } from 'ref-client-core/state/map/tiles/constants';
import { CHARGING_STATION_TABS } from './constants';
import axios from 'axios';

const CHARGING_STATIONS_CACHE = {};

const getNextAtChargingStation = (chargingStations) => {
  let length = chargingStations.length;
  let i = length - 1;
  let current = chargingStations[i];
  for(; current.distance < current.excursionDistance;) {
    if (i===0) break;
    current = chargingStations[--i];
  }
  if (i === 0) {
    return chargingStations[length-1];
  }
  else return current;
}

const chargingStationsFilter = (ChargingStationsUnsorted, ChargingStationsMap) => {
  let chargingStations = [];
  let newChargingStation = false;
  ChargingStationsUnsorted.forEach((item)=>{
    const { id } = item;
    if (!ChargingStationsMap[id]) {
      ChargingStationsMap[id] = item;
      chargingStations.push(item);
      newChargingStation = true;
    }
  });
  if (newChargingStation) return chargingStations;
  else return newChargingStation;
}

export const getChargingStationsGS7 = async (bboxes, routePoints, fields, settings) => {
  const {
    searchAts,
    inputType,
    width
  } = fields;

  const categories = ['700-7600-0322','700-7600-0323','700-7600-0324'];
  const filters = ['show=ev', 'limit=100', `categories=${categories.join(',')}`];
  const evStationFilters = ['minPower', 'connectorTypeIds', 'supplierNames', 'current', 'eMobilityServiceProviderPartnerIds'];
  evStationFilters.forEach(filter => {
    if (fields[`evStation[${filter}]`]) {
      filters.push(`${encodeURIComponent(`evStation[${filter}]`)}=${fields[`evStation[${filter}]`]}`);
    }
  });

  const queries = [];

  if (inputType === CHARGING_STATION_TABS.BBOX) {
    bboxes.forEach(bbox => {
      const [topRight, bottomLeft] = bbox.geometry;
      const at = `at=${topRight[0]/2+bottomLeft[0]/2},${topRight[1]/2+bottomLeft[1]/2}`;
      const query = `${at}&in=bbox:${bottomLeft[1]},${bottomLeft[0]},${topRight[1]},${topRight[0]}&${filters.join('&')}`;
      queries.push(query);
    })
    return await getChargingStationsBBox(queries, settings);
  } else {
    let chargingStationsMap = {};
    const chargingStations = [];
    const sats = JSON.parse(searchAts);
    const searchAt = sats.shift();
    const queryAt = `at=${searchAt.lat},${searchAt.lng}`;
    const query = `route=${routePoints};w=${width}&${filters.join('&')}`;

    return getChargingStationsRoute(queryAt, query, sats, settings, chargingStations, chargingStationsMap);
  }
}

const makeRequest = async (queryAt, queryEv, searchAts, settings, chargingStations, chargingStationsMap) => {
  const request = new Promise((resolve, reject) => {
    const { type, token, apikey } = settings;
    const query = `${queryAt}&${queryEv}`;
    if (CHARGING_STATIONS_CACHE[query]) {
      resolve(CHARGING_STATIONS_CACHE[query]);
      return;
    }
    let apiKey;
    let headers = { 'Content-Type': 'application/json' };
    if ( type === "hereToken" ) {
      headers['Authorization'] = "Bearer " + token;
    } else if ( type === 'apiKey' || type === 'credentials') {
      apiKey = apikey;
    }
    const url = `https://browse.search.hereapi.com/v1/browse?${query}${apiKey ? '&apikey=' + apikey : ''}`;
    axios
      .get(url, { headers })
      .then(({data}) => {
        resolve(data);
        CHARGING_STATIONS_CACHE[query] = data;
      })
      .catch((err) => {
        reject(err);
      });
  });
  try {
    const res = await request;
    const { items } = res;
    const filteredItems = chargingStationsFilter(items, chargingStationsMap);
    if (!filteredItems && searchAts.length === 0) {
      return chargingStations;
    } else if(filteredItems) {
      const nextAtChargingStation = getNextAtChargingStation([...items]);
      const { position } = nextAtChargingStation;
      const nextChargingStations = await makeRequest(`at=${position.lat},${position.lng}`, queryEv, searchAts, settings, filteredItems, chargingStationsMap)
      return [...chargingStations, ...nextChargingStations];
    } else {
      const nextAtChargingStation = searchAts.shift();
      const { lat, lng } = nextAtChargingStation;
      const nextChargingStations = await makeRequest(`at=${lat},${lng}`, queryEv, searchAts, settings, [], chargingStationsMap)
      return [...chargingStations, ...nextChargingStations];
    }
  } catch (e) {
    if (e.response) {
      return {...e.response, isError: true}
    } else if (e.config.url.length > 6200){
      return {
        data: { status: 414, title: "URI length exceeds the configured limit of 6144 characters. Please search with a shorter route."}, 
        isError: true
      }
    } else {
      return {...e.response, isError: true}
    }
  }
}

const getChargingStationsRoute = async (queryAt, queryEv, searchAts, settings, chargingStations, chargingStationsMap) => {

  return makeRequest(queryAt, queryEv, searchAts, settings, chargingStations, chargingStationsMap);
}

const getChargingStationsBBox = (queries, settings) => {
  const { type, token, apikey } = settings;
  const promises = queries.map(query => {
    return new Promise((resolve, reject) => {
      if (CHARGING_STATIONS_CACHE[query]) {
        resolve(CHARGING_STATIONS_CACHE[query]);
        return;
      }
      let apiKey;
      let headers = { 'Content-Type': 'application/json' };
      if ( type === "hereToken" ) {
        headers['Authorization'] = "Bearer " + token;
      } else if ( type === 'apiKey' || type === 'credentials') {
        apiKey = apikey;
      }
      const url = `https://browse.search.hereapi.com/v1/browse?${query}${apiKey ? '&apikey=' + apikey : ''}`;
      axios
        .get(url, { headers })
        .then(({data}) => {
          resolve(data.items);
          CHARGING_STATIONS_CACHE[query] = data.items;
        })
        .catch((err) => {
          reject(err);
        });
    })
  })
  return Promise.all(promises);
}

export const useIcon = (colorPalette) => {
  const iconTexts = ['', '1', '3', '13', '1D', '3D', '13D'];

  const iconJSLA = {};
  iconTexts.forEach(title => {
    const svg = MarkerIcon({
      title,
      color: colorPalette.primary,
      strokeColor: '#000000',
    });
    const selectedSvg = MarkerIcon({
      title,
      color: colorPalette.primaryDarker,
      strokeColor: '#000000',
    });

    const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
    const selectedIconUrl = `data:image/svg+xml;base64,${btoa(selectedSvg)}`;

    iconJSLA['icon'+title] = new window.H.map.Icon(iconUrl, {
      anchor: { x: 11.5, y: 28 },
      size: { w: 23, h: 28 },
    });
    
    iconJSLA['selectedIcon'+title] = new window.H.map.Icon(selectedIconUrl, {
      anchor: { x: 11.5, y: 28 },
      size: { w: 23, h: 28 },
    });
  })


  const svg = MarkerIcon({
    color: colorPalette.primary,
    strokeColor: '#000000',
  });
  const selectedSvg = MarkerIcon({
    color: colorPalette.primaryDarker,
    strokeColor: '#000000',
  });
  const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
  const selectedIconUrl = `data:image/svg+xml;base64,${btoa(selectedSvg)}`;

  const icons = useRef({
    [TILE_LIBRARY_TYPES.LEAFLET]: {
      icon: new Icon({
        iconUrl,
        iconRetinaUrl: iconUrl,
        iconAnchor: [12, 28],
        iconSize: [23, 28],
      }),
      selectedIcon: new Icon({
        iconUrl: selectedIconUrl,
        iconRetinaUrl: selectedIconUrl,
        iconAnchor: [12, 28],
        iconSize: [23, 28],
      }),
    },
    [TILE_LIBRARY_TYPES.JSLA]: iconJSLA,
  }).current;
  return icons;
};
