import React, { useEffect, useState }from 'react';
import { connect } from 'react-redux';
import BoundingBox from 'ref-client-core/core/formViewsPlugins/regionDefinition/BoundingBox';
import PropTypes from 'prop-types';
import './chargingStations.scss';
import { Checkbox, TabsControl, Button, Textarea, FormRow, ExpandFormRow, CloseButton } from '@here/ref-client-ui';
import { CHARGING_STATION_TABS } from './constants';
import { getChargingStationsGS7 } from './helpers';
import BigNumber from 'bignumber.js';
import { encode, decode } from 'utils/flexPolyline';
import { simplify } from '@turf/turf';
import MultiplePoints from 'shared/multiplePoints';
import { getTitle } from 'state/tabs/tab/title';
import { fetchSettings } from 'state/tabs/tab/response/actions';
import Slider from 'rc-slider';

const SLIDER_VALUE_MAP = {
  0: 50,
  1: 100,
  2: 200,
  3: 500,
  4: 1000,
  5: 2000,
  6: 3000,
  7: 5000,

  50: 0,
  100: 1,
  200: 2,
  500: 3,
  1000: 4,
  2000: 5,
  3000: 6,
  5000: 7
}

const tabsData = Object.keys(CHARGING_STATION_TABS).map((key) => ({
  label: CHARGING_STATION_TABS[key],
  name: CHARGING_STATION_TABS[key],
}));


function ChargingStationsInputs(opts) {
  const { fields, setFields, setResultState, setNotification, selectedTab, fetchSettings } = opts;
  const { bbox = [], showBbox, points, searchAts, width, inputType } = fields;

  const [routePoints, setRoutePoints] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect((e) => {
    if (
      (inputType === CHARGING_STATION_TABS.BBOX) ||
      (points.length === 2 && inputType === CHARGING_STATION_TABS.ALONG_THE_ROUTE)
    ) {
      return;
    }

    const pointsValue = [];
    const ps = JSON.parse(points);
    const coords = [];
    ps.forEach(({lat, lng}) => {
      coords.push([lng, lat]);
    })
    
    const simplified = simplify({
      "type": "Feature",
      "geometry": {
        "type": "LineString",
        "coordinates": coords
      }
    }, {tolerance: 0.0005, highQuality: false});

    const { coordinates } = simplified.geometry;
    
    coordinates.forEach(([lng, lat]) => {
      if(lat !== "NaN" && lng !== "NaN" && lat !== undefined && lng !== undefined && lat !== null && lng !== null && lat !== "" && lng !== "") {
        pointsValue.push([new BigNumber(+lat), new BigNumber(+lng), new BigNumber(0)]);
      }
    });
    // const cs = []
    setRoutePoints(encode(pointsValue));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  const onRemove = (index) => {
    const { bbox } = fields;
    const newBounds = [
      ...bbox.slice(0, index),
      ...bbox.slice(index + 1),
    ];
    setFields({ bbox: newBounds });
  };

  const onChange = (index, bound) => {
    const { bbox } = fields;
    const newBounds = [
      ...bbox.slice(0, index),
      {geometry: bound, type: 'bbox'},
      ...bbox.slice(index + 1),
    ];
    setFields({ bbox: newBounds });
  };
  const addBBox = () => {
    const { bbox } = fields;
    const newBounds = [
      ...bbox,
      {geometry: [[],[]], type: 'bbox'}
    ];
    setFields({ bbox: newBounds });
  }

  const toggleBbox = ({ target }) => {
    if (showBbox !== target) {
      setFields({ showBbox: target.checked });
    }
  };

  const request = () => {
    const { bbox } = fields;
    
    setFields({ isLoading: true });

    loadData().then((res) => {
      setFields({ isLoading: false });

      setResultState({
        isResultPanelShown: true,
        data: res.isError ? [] : res,
        selectedStation: null,
        error: res.isError? res : null,
      });
    });

    
    async function loadData() {
      try{
        const settings = await fetchSettings(selectedTab);
        const results = await getChargingStationsGS7(bbox, routePoints, fields, settings);
        if (fields.inputType === CHARGING_STATION_TABS.BBOX) {
          let temp = [];
          results.forEach((result, i) => {
            if (result.length === 100) {
              setNotification({
                message: `Search in Bounding Box ${i+1} reached the maximum limit of 100 results.`,
                impact: 'significant',
                autoDismiss: 5,
              });
            }
            temp = [...temp, ...result];
          })
          return temp;
        } else {
          return results;
        }
      } catch(e) {
        return {...e.response, isError: true};
      }
    }
  }

  const setPoints = ({ target: {value} }) => {
    try{
      const v = decode(value).map((latLng) => ({
        lat: +latLng[0],
        lng: +latLng[1],
      }));
      setFields({ 
        points: JSON.stringify(v),
        searchAts: JSON.stringify([v[0]])
      });
    } catch (e) {}
  }

  const onChangeSearchAts = (e) => {
    setFields({ searchAts: JSON.stringify(e), numbers: searchAts.length });
  }

  const onSetWidth = (e) => {
    setFields({ width: SLIDER_VALUE_MAP[e] });
  }

  const getTabEl = () => {
    if (inputType === CHARGING_STATION_TABS.BBOX) {
      const bboxEls = bbox.map((box, index) => {
        return (<ExpandFormRow
          key={index}
          label={`Bounding Box ${index+1}`}
          isExpanded
          tooltipPlacement="right"
          className={`rf-grey-box rf-bounding-box`}
        >
          <CloseButton className={`rf-close-button`} onClick={onRemove.bind(this, index)} />
          <BoundingBox
            bounds={box.geometry}
            onChange={onChange.bind(this, index)}
          />
        </ExpandFormRow>)
      })
      return (
        <>
          {bboxEls}
          <Button
            title="Add Bounding Box"
            onClick={addBBox}
          />
          <Checkbox
            label="Show / Hide Bounding Box"
            isChecked={showBbox}
            onChange={toggleBbox}
          />
        </>
      );
    } else {
      return (
        <FormRow className="rf-charging-stations__points">
          {routePoints==='' && <div className="rf-charging-stations__points">
            Build a route in Routing V8 -&gt; Right click on it and select
            "Charging stations along the route" or input a Flexible Polyline 
            in text area
          </div>}
          <div className='rf-input__caption rf-charging-stations__slider-text'>Search along the route with width: {width}m</div>
          <div className="rf-charging-stations__slider">
            <Slider
              value={SLIDER_VALUE_MAP[width]}
              onChange={onSetWidth}
              onAfterChange={onSetWidth}
              min={0}
              step={1}
              max={7}
              dots
            />
          </div>
          <Textarea
            label={"Charging stations along the route"}
            value={routePoints}
            onBlur={setPoints}
          />
          <MultiplePoints
            label="Outer"
            points={JSON.parse(searchAts) || []}
            onChange={onChangeSearchAts}
            noTabs={true}
          />
        </FormRow>
      );
    }
  };

  return (
    <div className="rf-charging-stations">
      <TabsControl
        currentTabName={inputType}
        onChange={(e) => setFields({ inputType: e })}
        tabsData={tabsData}
        className="rf-charging-stations__tabs"
      />
      {getTabEl()}                  
      <Button
        className="rf-charging-stations-request"
        title="Request"
        onClick={request}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    title: getTitle(state),
    selectedTab: state.selectedTab,
  };
}

const mapDispatchToProps = {
  fetchSettings
};

ChargingStationsInputs.propTypes = {
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};

const chargingStationsInputPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({
    bbox: undefined,
    showBbox: true,
    inputType: CHARGING_STATION_TABS.BBOX,
    points: '[]',
    searchAts: '[]',
    width: 3000,
    'evStation[minPower]': '100',
    'evStation[connectorTypeIds]': undefined,
    'evStation[supplierNames]': undefined,
    'evStation[current]': undefined,
    'evStation[eMobilityServiceProviderPartnerIds]': undefined,
  }),
  getRequestOptions: () => ({}),
  Component: connect(mapStateToProps, mapDispatchToProps)(ChargingStationsInputs),
};

export default chargingStationsInputPlugin;
