import React from 'react';
import { Button, FormRow } from '@here/ref-client-ui';
import './maneuversVisualizationButton.scss';

function ManeuversVisualizationButton({
  fields: { filteredManeuvers, legal, artic, rigid, comment, fid, hereFiltered, hereName },
  setResultState,
}) {
  const show = () => {
    try {
      if (filteredManeuvers && filteredManeuvers.length) {
        const filteredM = []
        filteredManeuvers.forEach(feature => {
          const { not_legal, max_rigid, max_artic, extra_comment, ogc_fid, hereProperties } = feature.properties;
          const { filtered, name } = hereProperties;
          if ((legal === 'not legal' && not_legal === '')
          || (legal === 'legal' && not_legal === '1')

          || (rigid.values.length && rigid.values.indexOf(max_rigid) === -1)
          || (artic.values.length && artic.values.indexOf(max_artic) === -1)

          || (comment && extra_comment.toLowerCase().indexOf(comment.toLowerCase()) === -1)

          || (fid && fid !== ogc_fid)
          
          || (hereFiltered === 'true' && filtered === undefined)
          || (hereFiltered === 'false' && filtered === true)

          || (hereName && name.toLowerCase().indexOf(hereName.toLowerCase()) === -1)
          ) {
            // filtered out
          } else{
            filteredM.push(feature);
          }
        })
        
        setResultState({
          maneuvers: filteredM,
          selectedManeuver: filteredM.length ? 0 : null,
          isResultPanelShown: true,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <FormRow className="rf-maneuver-visualization-form-view">
      <Button title="Show" onClick={show} />
    </FormRow>
  );
}

const maneuversVisualizationPluginButton = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: ManeuversVisualizationButton,
};

export default maneuversVisualizationPluginButton;
