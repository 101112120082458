import formViewsPlugin from '../../core/formViewsPlugin';
import { getTitle } from '../tabs/tab/title';
import { isFunction } from 'lodash';
import refClient from '../../core/refClient';

export const getConfig = (state) => ({
  ...state.config.default,
  ...state.config.custom,
});

export const getConfigStateData = (
  configs,
  settings,
  defaultPresets,
  moduleTitle
) => {
  const errors = [];
  const fields = configs[moduleTitle].inputs.reduce((acc, input) => {
    const { type, options } = input;
    try {
      const { defaultState } = formViewsPlugin.get(type);
      const initState = isFunction(defaultState)
        ? defaultState(options, refClient)
        : defaultState;

      return {
        ...acc,
        ...initState,
      };
    } catch (e) {
      errors.push(e);
      return acc;
    }
  }, {});

  const defaultPreset = defaultPresets[moduleTitle];
  let preset;
  if (defaultPreset && settings[moduleTitle][defaultPreset]) {
    preset = defaultPreset;
  } else {
    preset = Object.keys(settings[moduleTitle])[0];
  }

  return { fields, preset, errors };
};

export const getCurrentConfig = (state, tabIndex) => {
  const configs = getConfig(state);
  const title = getTitle(state, tabIndex);

  return configs[title];
};


export const isInternal = (config) => {  
  // interal ref-client has "Inspect Segment" tab, while external ref-client does not
  return config['Inspect Segment'] ? true : false;
}