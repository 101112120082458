// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { inputType, searchAts } = props.fields;
  if ( inputType !== 'Along the route' || !searchAts ) {
    return [];
  }

  const markers = [];
  JSON.parse(searchAts).forEach((searchAt, i) => {
    markers.push({
      value: `${searchAt.lat},${searchAt.lng}`,
      draggable: false,
      text: i+1,
      id: i
    });
  });

  return markers;
};
