import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const maneuvers = get(props, 'result.state.maneuvers');
  if (!maneuvers) {
    return [];
  }
  const currentRouteMarkers = [];
  const selectedManeuverIndex = get(props, 'result.state.selectedManeuver');
  if (typeof selectedManeuverIndex === 'number' && maneuvers[selectedManeuverIndex]) {
    const { coordinates } = maneuvers[selectedManeuverIndex].geometry;
    const [ lnga, lata ] = coordinates[0];
    currentRouteMarkers.push({
      value: `${lata},${lnga}`,
      text: 'A',
      draggable: false,
      id: 0
    });
    const [ lngb, latb ] = coordinates[coordinates.length-1];
    currentRouteMarkers.push({
      value: `${latb},${lngb}`,
      text: 'B',
      draggable: false,
      id: 1
    });
  }


  return [...currentRouteMarkers];
};
