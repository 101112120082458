const toFixed6 = (val) => +val.toFixed(6);

// eslint-disable-next-line import/no-anonymous-default-export
export default (fields, e) => {
  const {
    bbox
  } = fields;
  const {
    xy: { x, y },
    xyToLatLng,
  } = e;

  const padding = 100;
  const topLeftPoint = { x: x - padding, y: y - padding };
  const bottomRightPoint = { x: x + padding, y: y + padding };
  const topLeft = xyToLatLng(topLeftPoint);
  const bottomRight = xyToLatLng(bottomRightPoint);

  return {
    bbox: [
      ...bbox,
      {
        geometry:[
          [toFixed6(topLeft.lat), toFixed6(bottomRight.lng)],
          [toFixed6(bottomRight.lat), toFixed6(topLeft.lng)],
        ],
        type: "bbox"
      }
    ]
  };
};
