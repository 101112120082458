import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

function InspectSegment(PolylineComponent, CircleComponent) {
  return class extends React.Component {
    static propTypes = {
      fields: PropTypes.object.isRequired,
      colorPalette: PropTypes.object,
      result: PropTypes.object.isRequired,
    };

    getSelectedTruckAttribute = () => {
      const {
        result: {
          state: { selectedAttribute },
        },
        fields: { inspectSegmentData = {} },
        colorPalette,
      } = this.props;
      if (selectedAttribute != null) {
        const geometry = get(inspectSegmentData, [
          selectedAttribute.layerId,
          'parsed',
          selectedAttribute.index,
          'geometry',
        ]);
        if (geometry) {
          return geometry.length > 1 ? (
            <PolylineComponent
              positions={geometry}
              color={colorPalette.primaryDarker}
              weight={6}
            />
          ) : (
            <CircleComponent
              center={{ lat: geometry[0].lat, lng: geometry[0].lng }}
              color={colorPalette.primary}
              radius={15}
            />
          );
        }
      }

      return null;
    };

    render() {
      const {
        fields: { inspectSegmentData = {} },
        colorPalette,
      } = this.props;
      if (!inspectSegmentData['topology-geometry'] || !inspectSegmentData['topology-geometry'].parsed) {
        return null;
      }

      const { parsed } = inspectSegmentData['topology-geometry'];
      const { geometry = [] } = parsed;
      const start = get(parsed, 'startNode.geometry');
      const end = get(parsed, 'endNode.geometry');
      let startCircle = null;
      let endCircle = null;
      if (start) {
        startCircle = (
          <CircleComponent
            center={{ lat: start.latitude, lng: start.longitude }}
            color="#00FF00"
            radius={30}
          />
        );
      }
      if (end) {
        endCircle = (
          <CircleComponent
            center={{ lat: end.latitude, lng: end.longitude }}
            color="#000000"
            radius={30}
          />
        );
      }
      const latLngs = geometry.map((latLng) => ({
        lat: latLng.latitude,
        lng: latLng.longitude,
      }));
      return (
        <>
          <PolylineComponent
            positions={latLngs}
            color={colorPalette.primary}
            weight={4}
          />
          {startCircle}
          {endCircle}
          {this.getSelectedTruckAttribute()}
        </>
      );
    }
  };
}

export default InspectSegment;
