import React, { useState, useEffect } from 'react';
import { FormRow } from '@here/ref-client-ui';
import './maneuversVisualization.scss';
import Papa from 'papaparse';

function ManeuversVisualization({
  setFields,
}) {
  const [allManeuvers, setAllManeuvers] = useState({});
  const [rules, setRules] = useState([]);

  useEffect(() => {
    try {
      const { features } = allManeuvers;
      if (features && rules.length) {
        const converted = [];
        rules.forEach(tagged => {
          const { ogc_fid } = tagged;
          const id = ogc_fid - 1;
          converted.push({
            ...features[id],
            properties: {
              hereProperties: features[id].properties,
              ...tagged
            }
          });
        })

        setFields({
          filteredManeuvers: converted,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [allManeuvers, rules, setFields]);

  const changeFileManeuvers = ({ target }) => {
    var files = target.files;
    var file = files[0];
    if (file) {
      var r = new FileReader();
      r.onload = (e) => { 
        setAllManeuvers(JSON.parse(r.result));
      }
      r.readAsText(file);
    } else {
      setAllManeuvers({});
    }
  };
  const changeFileTaggedManeuvers = ({ target }) => {
    var files = target.files;
    var file = files[0];
    if (file) {
      var r = new FileReader();
      r.onload = (e) => { 
        Papa.parse(r.result, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setRules(results.data);
          }
        });
      }
      r.readAsText(file);
    } else {
      setRules([]);
    }
  };


  const dragOver = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'copy';
  }

  const dropManeuvers = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    var files = evt.dataTransfer.files;
    evt.target.files = files;
    var file = files[0];
    if (file) {
      var r = new FileReader();
      r.onload = (e) => { 
        setAllManeuvers(JSON.parse(r.result));
      }
      r.readAsText(file);
    } else {
      setAllManeuvers({});
    }
  }

  const dropTaggedManeuvers = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    var files = evt.dataTransfer.files;
    evt.target.files = files;
    var file = files[0];
    if (file) {
      var r = new FileReader();
      r.onload = (e) => { 
        Papa.parse(r.result, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            setRules(results.data);
          }
        });
      }
      r.readAsText(file);
    } else {
      setRules([]);
    }
  }

  return (
    <FormRow className="rf-maneuver-visualization-form-view rf-grey-box">
      <div
        onDrop={dropManeuvers}
        onDragOver={dragOver}
      >
        <span className="rf-input__caption">Maneuvers (.geojson)</span>
        <span className="rf-input__container">
          <div className="rf-input__field" type="text">
            <input
              className="lui-textfield__seed rf-file-input"
              onChange={changeFileManeuvers}
              type="file"
              accept=".geojson,application/geo+json"
            />
          </div>
        </span>
      </div>
      <div
        onDrop={dropTaggedManeuvers}
        onDragOver={dragOver}
      >
        <span className="rf-input__caption">Interim Results (.csv)</span>
        <span className="rf-input__container">
          <div className="rf-input__field" type="text">
            <input
              className="lui-textfield__seed rf-file-input"
              onChange={changeFileTaggedManeuvers}
              type="file"
              accept=".csv,text/csv"
            />
          </div>
        </span>
      </div>
    </FormRow>
  );
}

const maneuversVisualizationPlugin = {
  parseUrl: () => ({}),
  defaultState: () => ({}),
  getRequestOptions: () => ({}),
  Component: ManeuversVisualization,
};

export default maneuversVisualizationPlugin;
