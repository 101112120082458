import { BodyEnd, FormRow } from '@here/ref-client-ui';
import OutsideClickHandler from '@here/ref-client-ui/lib/outsideClickHandler';

function HoveredStationTooltip({
  position: { top, left },
  stations,
  onMouseEnter,
  onMouseLeave,
}) {
  // connectorType, numberOfConnectors, supplierName
  const stationEls = stations.map(({ connectorType, numberOfConnectors, supplierName, maxPowerLevel}, stationIndex) => (
    <div className="rf-hovered-station-tooltip__station" key={stationIndex}>
      <FormRow key={`${stationIndex}`}>
        <label className="rf-input rf-input_readonly">
          <strong className="rf-input__caption">
            Type: {connectorType}
          </strong>
        </label>
        <label className="rf-input rf-input_readonly">
          <strong className="rf-input__caption">
            MaxPowerLevel: {`${maxPowerLevel} kW`}
          </strong>
        </label>
        <label className="rf-input rf-input_readonly">
          <strong className="rf-input__caption">
            Number: {numberOfConnectors}
          </strong>
        </label>
      </FormRow>
      <FormRow>
        <label className="rf-input rf-input_readonly">
          <strong className="rf-input__caption">Brand: {supplierName}</strong>
        </label>
      </FormRow>
    </div>
  ));
  return (
    <BodyEnd>
      <OutsideClickHandler outsideClick={onMouseLeave}>
        <div
          className="rf-hovered-station-tooltip"
          style={{ left: left - 15, top: top - 15 }}
          onMouseEnter={onMouseEnter}
        >
          {stationEls}
        </div>
      </OutsideClickHandler>
    </BodyEnd>
  );
}

export default HoveredStationTooltip;
