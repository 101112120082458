import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { latLngBounds } from 'leaflet';
import {
  validateLat,
  validateLon,
} from '../../../utils/validation/coordsValidation';
import './rectangleList.scss';

function RectangleList(RectComponent) {
  return class RectangleList extends React.Component {
    static propTypes = {
      options: PropTypes.object,
      fields: PropTypes.object,
      setFields: PropTypes.func.isRequired,
      selectedTab: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired,
      refClient: PropTypes.object.isRequired,
      paramsMapping: PropTypes.bool,
    };

    onChange = (index, bounds) => {
      const {
        options: { onChangeFormatter, key, path, mode = 'avoid' },
        setFields,
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      const boundsList = get(this.props, path);
      const bound = boundsList[index];
      bound.geometry = bounds;
      const newBoundsList = [
        ...boundsList.slice(0, index),
        bound,
        ...boundsList.slice(index + 1),
      ];

      if (onChangeFormatter) {
        const formatter = formatterPlugin.get(onChangeFormatter);
        setFields(formatter(fields, newBoundsList, mode));
      } else if (key) {
        setFields({ [key]: newBoundsList });
      }
    };

    onClick = (index) => {
      const {
        options: { onClickFormatter },
        refClient: { formatterPlugin },
        fields,
        setFields,
      } = this.props;

      if (onClickFormatter) {
        const formatter = formatterPlugin.get(onClickFormatter);
        setFields(formatter(fields, index));
      }
    };

    onContextMenu = (index, type, event, e) => {
      const { 
        options: { mode = 'avoid', onChangeExcludeFormatter },
        setFields, 
        fields,
        refClient: { formatterPlugin },
      } = this.props;
      const { [mode]: { areas } } = fields;

      if (onChangeExcludeFormatter) {
        if (type === 'remove') {
          const formatter = formatterPlugin.get(onChangeExcludeFormatter+'Remove');
          setFields(formatter(fields, e, mode, index));
        } else if (type === 'bbox' || type === 'polygon' || type === 'corridor'){
          const areaType = type.charAt(0).toUpperCase() + type.slice(1);
          const formatter = formatterPlugin.get(onChangeExcludeFormatter + areaType);
          setFields(formatter(fields, e, mode, true, areas[index].i));
        }
      }
    }

    render() {
      const {
        options: {
          path,
          showUnselected,
          color,
          exceptionColor,
          exceptionFillColor,
          fillColor,
          fillOpacity,
          draggable,
          resizable,
          skipCorridor,
          noException,
          mode,
          noContextMenu
        },
        selectedTab,
        tabIndex,
        paramsMapping,
        map
      } = this.props;

      if (tabIndex !== selectedTab && !showUnselected) {
        return null;
      }
      const boundsList = get(this.props, path);

      if (!boundsList) {
        return null;
      }
      
      return boundsList.map((bounds, index) => {
        const { type, isException } = bounds;
        if(type !== "bbox") {
          return null;
        }
        bounds = bounds.geometry;
        const [[lat1, lng1] = [], [lat2, lng2] = []] = bounds || [];
        if(lat1 === null || lat2 === null || lng1 === null || lng2 === null ){
          return null;
        }
        const isCoordsValid =
          validateLat(lat1) &&
          validateLon(lng1) &&
          validateLat(lat2) &&
          validateLon(lng2);
        if (!isCoordsValid || !latLngBounds(bounds).isValid()) {
          return null;
        }
        return (
          <div key={index}>
            <RectComponent
              bounds={bounds}
              contextMenu={!noContextMenu}
              noException={!!noException}
              onContextMenu={this.onContextMenu.bind(this, index)}
              skipCorridor={skipCorridor}
              map={map}
              onChange={this.onChange.bind(this, index)}
              onclick={this.onClick.bind(this, index)}
              color={isException ? exceptionColor : color}
              fillColor={isException ? exceptionFillColor : fillColor}
              fillOpacity={fillOpacity}
              draggable={draggable && paramsMapping}
              resizable={resizable && paramsMapping}
              mode={mode}
              isException={isException}
            />
          </div>
        );
      });
    }
  };
}

export default RectangleList;
