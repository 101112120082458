import React from 'react';
import PropTypes from 'prop-types';
import { isConditionsSatisfied } from '../../utils';
import { isFunction, merge } from 'lodash';
import { FormRow, Checkbox, ExpandFormRow } from '@here/ref-client-ui';

class GroupFormView extends React.Component {
  onIncludeParamsCheckboxChange = ({ target }) => {
    const {
      setFields,
      options: {
        includeParamsCheckbox: { key },
      },
    } = this.props;
    setFields({ [key]: target.checked });
  };

  getIncludeParamsCheckboxEl = () => {
    const {
      options: { includeParamsCheckbox },
      fields,
    } = this.props;

    if (includeParamsCheckbox) {
      const { key, label } = includeParamsCheckbox;
      return (
        <FormRow>
          <Checkbox
            isChecked={fields[key]}
            label={label}
            onChange={this.onIncludeParamsCheckboxChange}
          />
        </FormRow>
      );
    }

    return null;
  };

  render() {
    const { options, fields, setFields, refClient, settings, request, setNotification } = this.props;
    const { formViewsPlugin } = refClient;
    const { inputs, label, isExpanded, cssClasses, conditions } = options;

    if (!isConditionsSatisfied(this.props, conditions)) {
      return null;
    }

    const inputEls = inputs.map((input, index) => {
      const { type, options = {} } = input;
      const { groupConditions } = options;
      if (!isConditionsSatisfied(this.props, groupConditions)) {
        return null;
      }
      const { Component } = formViewsPlugin.get(type);

      return (
        <Component
          key={`${index}${type}`}
          options={options}
          fields={fields}
          setFields={setFields}
          refClient={refClient}
          settings={settings}
          request={request}
          setNotification={setNotification}
        />
      );
    });

    return (
      <ExpandFormRow
        label={label}
        isExpanded={!!isExpanded}
        className={cssClasses}
      >
        {this.getIncludeParamsCheckboxEl()}
        {inputEls}
      </ExpandFormRow>
    );
  }
}

GroupFormView.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  refClient: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parseUrl: ({
    parsedParams,
    options,
    hostAndPath,
    settings,
    postData = {},
    refClient,
  }) => {
    const { formViewsPlugin } = refClient;
    const { inputs, includeParamsCheckbox } = options;
    const params = inputs.reduce((acc, input) => {
      const { type, options } = input;
      const a = formViewsPlugin.get(type).parseUrl({
        parsedParams,
        options,
        hostAndPath,
        settings,
        postData,
        refClient,
      })
      return {
        ...acc,
        ...a,
      };
    }, {});

    if (includeParamsCheckbox) {
      const { key } = includeParamsCheckbox;

      return { ...params, [key]: !!Object.keys(params).length };
    }

    return params;
  },
  defaultState: (options, refClient) => {
    const { formViewsPlugin } = refClient;
    const { inputs, includeParamsCheckbox } = options;
    const init = {};
    if (includeParamsCheckbox) {
      init[includeParamsCheckbox.key] = includeParamsCheckbox.value;
    }
    return inputs.reduce((acc, input) => {
      const { type, options } = input;
      const { defaultState } = formViewsPlugin.get(type);

      return {
        ...acc,
        ...(isFunction(defaultState)
          ? defaultState(options, refClient)
          : defaultState),
      };
    }, init);
  },
  getRequestOptions: (fields, options, refClient) => {
    const { formViewsPlugin } = refClient;
    const { inputs, includeParamsCheckbox, conditions } = options;
    if (includeParamsCheckbox && !fields[includeParamsCheckbox.key]) {
      return {};
    }
    if (!isConditionsSatisfied({ fields }, conditions)) {
      return {};
    }
    return inputs.reduce((acc, input) => {
      const { type, options } = input;
      return merge(
        acc,
        formViewsPlugin.get(type).getRequestOptions(fields, options, refClient)
      );
    }, {});
  },
  Component: GroupFormView,
};
