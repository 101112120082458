import RefClient from 'ref-client-core';
import flexPolylineFormViewPlugin from './flexPolylineFormView';
import maneuversVisualizationPlugin from './maneuversVisualization';
import maneuversVisualizationPluginButton from './maneuversVisualizationButton';
import distanceMeasurementFormView from './distanceMeasurement';
import FlexPolylineResult from './FlexPolylineResult';

RefClient.formViewsPlugin.register('flexPolylineFormView', flexPolylineFormViewPlugin);
RefClient.formViewsPlugin.register('distanceMeasurementFormView', distanceMeasurementFormView);
RefClient.resultPanelPlugin.register('flexPolylineResult', FlexPolylineResult);
RefClient.formViewsPlugin.register('maneuversVisualization', maneuversVisualizationPlugin);
RefClient.formViewsPlugin.register('maneuversVisualizationButton', maneuversVisualizationPluginButton);
