import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import OlsWaypointAdvanced from '../olsWaypoint/olsWaypointAdvanced';
import AdvancedPointFields from './AdvancedCorridorPointsComponent';
import utils from 'utils';

class AdvancedViaFields extends AdvancedPointFields {
  parsePointsArr = (value) => [...value];

  parseCoords = (values) => values.map((value) => value.coords);

  stringifyPoints = (points) =>
    stringifyViaWaypoints(points)
      .map((point) => `via=${point}`)
      .join('&');

  onChangePoint = (num, e) => {
    let coords = utils.extractData(e).replace(/\s/g, '');
    const {
      fields,
      options: { key },
      setFields,
    } = this.props;
    const pointsArr = this.parsePointsArr(fields[key]);
    pointsArr[num] = { ...pointsArr[num], coords };
    setFields({ [key]: pointsArr });
  };

  onRemovePoint = (num) => {
    const {
      fields,
      options: { key },
      setFields,
    } = this.props;
    const pointsArr = this.parsePointsArr(fields[key]);
    pointsArr.splice(num, 1);
    setFields({ [key]: pointsArr });
  };

  onAddPoint = () => {
    const {
      fields,
      options: { key },
      setFields,
    } = this.props;
    setFields({
      [key]: [
        ...fields[key],
        { coords: '', advanced: getAdvancedWaypointParams() },
      ],
    });
  };

  onAdvancedChanged = (num, param, e) => {
    const {
      fields,
      options: { key },
      setFields,
    } = this.props;
    const via = [...fields[key]];
    via[num].advanced[param] = utils.extractData(e);
    setFields({ [key]: via });
  };

  setPoints = (e) => {
    const {
      options: { key },
      setFields,
    } = this.props;
    const points = e.target.value;
    const value = points
      .split('&')
      .map((val) => val.replace(/^via=/, ''))
      .filter(Boolean)
      .map((val) => splitAdvancedWaypointParams(val));
    setFields({ [key]: value });
  };

  handleSort = ({newIndex, oldIndex}) => {
    const {
      options: { key },
      setFields,
      fields,
    } = this.props;
    const via = fields[key];

    const item = via[oldIndex];
    via.splice(oldIndex, 1);
    via.splice(newIndex, 0, item);
    setFields({ [key]: via });
  };

  getAdvancedWaypointContent = (point, num) => {
    const {
      fields,
      options: { key },
      setNotification
    } = this.props;
    return (
      <OlsWaypointAdvanced
        fields={fields}
        data={fields[key][num].advanced}
        onChange={this.onAdvancedChanged.bind(this, num)}
        setNotification={setNotification}
      />
    );
  };
}

AdvancedViaFields.propTypes = {
  fields: PropTypes.object,
  options: PropTypes.object,
  setFields: PropTypes.func.isRequired,
};

export function splitAdvancedWaypointParams(waypointStr = '') {
  let [waypointAndPlaceOptions, ...waypointOptions] = waypointStr.split('!');
  let [coords, ...placeOptions] = waypointAndPlaceOptions.split(';');
  const advancedFields = {};
  const advancedWaypointParams = getAdvancedWaypointParams();

  const getParams = (delimiter, option) => {
    let [key, ...value] = option.split('=');
    value = value.join('=');

    if (advancedWaypointParams.hasOwnProperty(key)) {
      advancedFields[key] = value;
    } else {
      const extraParamsKey = `extraParams`;
      const isPlaceOptions = delimiter === ';';
      const withDelimiter = delimiter + option;
      advancedFields[extraParamsKey] = advancedFields.hasOwnProperty(extraParamsKey)
      ? (isPlaceOptions ? withDelimiter + advancedFields[extraParamsKey] : advancedFields[extraParamsKey] + withDelimiter)
      : withDelimiter;
    }
  }
  waypointOptions.forEach(getParams.bind(this, '!'));
  placeOptions.forEach(getParams.bind(this, ';'));
  
  return {
    coords: coords.replace(' ', ''),
    advanced: {
      ...advancedWaypointParams,
      ...advancedFields,
    },
  };
}

function stringifyAdvancedWaypoint(data) {
  const waypointOptions = ['stopDuration', 'passThrough', 'charging', 'currentWeightChange'];
  let placeOptionsStr = '';
  let extraParamsStr = '';
  let waypointOptionsStr = '';

  Object.keys(data).forEach((key) => {
    let param = data[key];
    const value = get(param, 'value', param);

    if (value === '') {
      return;
    }

    if (key === 'extraParams') {
      extraParamsStr = `${value}`;
    } else if (waypointOptions.indexOf(key) !== -1) {
      waypointOptionsStr += `!${key}=${value}`;
    } else {
      placeOptionsStr += `;${key}=${value}`;
    }
  });
  return `${placeOptionsStr}${extraParamsStr}${waypointOptionsStr}`;
}

export function stringifyViaWaypoints(points) {
  return points.map(
    (value) => `${value.coords}${stringifyAdvancedWaypoint(value.advanced)}`
  );
}

export function getAdvancedWaypointParams() {
  return {
    course: '',
    sideOfStreetHint: '',
    displayLocation: '',
    uTurnPermission: '',
    matchSideOfStreet: '',
    nameHint: '',
    radius: '',
    snapRadius: '',
    minCourseDistance: '',
    customizationIndex: '',
    stopDuration: '',
    radiusPenalty: '',
    segmentIdHint: '',
    onRoadThreshold: '',
    passThrough: '',
    charging: '',
    currentWeightChange: '',
    extraParams: '',
  };
}

export default AdvancedViaFields;
