import React from 'react';
import PropTypes from 'prop-types';
import CustomTabs from '../../../views/shared/tabs';
import { ExpandFormRow, Tree } from '@here/ref-client-ui';
import ResultPanelErrors from '../../../views/resultPanel/ResultPanelErrors';
import './inspectSegmentResult.scss';
import TopologyGeometry from './TopologyGeometry';
import TruckAttributes from './TruckAttributes';
import get from 'lodash/get';

const TABS_DATA = [{ title: 'Parsed' }, { title: 'Raw' }];

const PARSED_COMPONENTS = {
  'topology-geometry': TopologyGeometry,
  'truck-attributes': TruckAttributes,
  'navigation-attributes': TruckAttributes,
  'advanced-navigation-attributes': TruckAttributes,
  'road-attributes': TruckAttributes,
};

class InspectSegmentResult extends React.Component {
  state = {
    selectedTab: 0,
  };

  getRawRes = () => {
    const {
      fields: { inspectSegmentData },
    } = this.props;

    return Object.keys(inspectSegmentData).map((layerId) => {
      const { raw } = inspectSegmentData[layerId];
      return (
        <ExpandFormRow label={layerId} isExpanded key={`raw${layerId}`}>
          <Tree data={raw || {}} />
        </ExpandFormRow>
      );
    });
  };

  getParsedRes = () => {
    const {
      fields: { inspectSegmentData, selectedLayers },
      setResultState,
      result,
    } = this.props;

    return selectedLayers
      .map(({ layer }) => {
        const parsed = get(inspectSegmentData, [layer, 'parsed']);
        const Component = PARSED_COMPONENTS[layer];
        if (parsed && Component) {
          return (
            <Component
              key={`parsed${layer}`}
              layerId={layer}
              data={parsed}
              setResultState={setResultState}
              result={result}
            />
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  onChangeTab = (e) => {
    this.setState({ selectedTab: e });
  };

  render() {
    const {
      fields: { inspectSegmentData }
    } = this.props;
    const { selectedTab } = this.state;

    let error = false;
    Object.keys(inspectSegmentData).forEach(key => {
      const layer = inspectSegmentData[key];
      if (layer && layer.raw && layer.raw.errorMessage) {
        const pattern = /({.*})/;
        const parsedMessage = layer.raw.errorMessage.match(pattern);
        if (!parsedMessage) {
          error = { data: layer.raw };
        } else {
          const errorMessage = parsedMessage[1];
          const parsedError = JSON.parse(errorMessage);
          error = {
            data: { layer: layer.raw.layer, ...parsedError }
          };
        }
      }
    })

    if (error) {
      return <ResultPanelErrors response={error} />;
    }

    return (
      <div className="rf-inspect-segment-result">
        <CustomTabs
          data={TABS_DATA}
          currentIndex={selectedTab}
          onChange={this.onChangeTab}
        />
        {selectedTab === 0 && this.getParsedRes('parsed')}
        {selectedTab === 1 && this.getRawRes()}
      </div>
    );
  }
}

InspectSegmentResult.propTypes = {
  result: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  setResultState: PropTypes.func.isRequired,
};

export default InspectSegmentResult;
